import React from 'react';

function ManagerHome(props) {
    return (
        <div className="h-screen bg-gray-50 w-full">
            <div className="container mx-auto sm:w-full md:w-5/6 pt-10 px-5 h-full">
                <div className="container mx-auto pt-10 mt-8 px-8">
                    <h1 className="float-left font-semibold text-2xl">Welcome to Kwanso Manager Dashboard</h1>
                    <div className="clear-both"></div>
                    {/* <h2 className="text-lg">View users and orders here</h2> */}
                    {/* <button className="float-right bg-gray-800 rounded-2xl text-xs uppercase px-3 py-2 text-gray-50 font-t hin"><i className="fa fa-plus"></i> Add Product</button> */}
                </div>
            </div>
        </div>
    );
}

export default ManagerHome;