import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { isAuth } from "../../Helpers/auth";
import { postData, uploadToFirebaseStorage } from "../../Helpers/request";
import { toast } from "react-toastify";
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

function AgentRegister(props) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [province, setProvince] = useState("");
  const [documents, setDocuments] = useState("");
  const [audio, setAudio] = useState("");
  const [btnTxt, setBtnTxt] = useState("Apply");
  const audioFile = React.createRef();
  const fileInput = React.createRef();

  const [verify, setVerify] = useState("");
  const h = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setBtnTxt("Processing Application... Please Wait");

    // upload File
    let _picture = uploadToFirebaseStorage(
      `agent/profile_images/`,
      documents
    );
    let _audio = uploadToFirebaseStorage(
      `agent/audio_note/`,
      audio
    );

    // get URL
    let _profile_path = await Promise.resolve(_picture);
    let _audio_path = await Promise.resolve(_audio);
    // console.log(fileInput);

    // push data to api
    let data = {
      name,
      email,
      phone,
      password,
      province,
      profile_image: _profile_path,
      audio: _audio_path,
    };
    console.log(data);
    // return;
    postData("/agent/register", data)
      .then((data) => {
        console.log(data);
        toast(data.message);
        if (data.error) {
          toast('Application Error. Your application failed.')
        } else {
          toast('Registration Successful. You\'d be alerted once your account is approved')
          setTimeout(function() {
            h.push("/agent/login");
          }, 1500)
        }
      })
      .catch((err) => {
        toast("Error: Application Failed. Try Registering once more");
        console.log("err", err);
      })
      .finally(() => setBtnTxt("Apply"))
  };

  return (
    <div className="w-full h-screen bg-gray-200 flex justify-center content-center">
      {isAuth() ? h.push("/dashboard") : null}
      <div className="m-auto bg-white mx-6 shadow-lg rounded border-2 h-5/6 overflow-y-auto border-gray-100">
        <div className="w-75 font-bold text-gray-600 mb-3 text-xl">
          <h1 className="cursor-pointer">
            <Link
              to="/agent/register"
              className="float-left p-6 text-green-500"
            >
              Agent Application&nbsp;
            </Link>
            <Link
              className="float-right p-6 bg-gray-100 text-gray-400"
              to="/agent/login"
            >
              &nbsp;Agent Login
            </Link>
          </h1>
        </div>
        <div className="border-t-2 border-gray-50 clear-both"></div>
        <form className="grid grid-cols-2" onSubmit={handleSubmit}>
          <div className="my-2 p-6 col-span-2 md:col-span-1">
            <div className="mt-3">
              <input
                className="w-full shadow rounded-md border-2 border-gray-100 hover:border-gray-200 p-3 font-semibold"
                placeholder="Your Full Name"
                value={name}
                required
                onChange={(e) => setName(e.target.value)}
                autoComplete="name"
              />
            </div>
            <div className="mt-3">
              <input
                className="w-full shadow rounded-md border-2 border-gray-100 hover:border-gray-200 p-3 font-semibold"
                placeholder="Province"
                value={province}
                required
                onChange={(e) => setProvince(e.target.value)}
                autoComplete="province"
              />
            </div>
            <div className="mt-3">
              <input
                className="w-full shadow rounded-md border-2 border-gray-100 hover:border-gray-200 p-3 font-semibold"
                placeholder="Phone Number"
                value={phone}
                required
                onChange={(e) => setPhone(e.target.value)}
                autoComplete="phone"
              />
            </div>
            <div className="mt-3">
              <input
                className="w-full shadow rounded-md border-2 border-gray-100 hover:border-gray-200 p-3 font-semibold"
                placeholder="Email"
                value={email}
                required
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                autoComplete="email"
              />
            </div>
            <div className="mt-3">
              <input
                className="w-full shadow rounded-md border-2 border-gray-100 hover:border-gray-200 p-3 font-semibold"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                required
                autoComplete="new-password"
              />
            </div>
            <div className="mt-3">
              <input
                className="w-full shadow rounded-md border-2 border-gray-100 hover:border-gray-200 p-3 font-semibold"
                placeholder="Verify Password"
                value={verify}
                required
                onChange={(e) => setVerify(e.target.value)}
                type="password"
                autoComplete="new-password"
              />
            </div>
            <div className="mt-3">
              <label htmlFor="doc">Your Profile Picture</label>
              <label htmlFor="file-upload">
                <div className="w-full shadow ring-1 cursor-pointer text-white bg-blue-400 hover:bg-blue-500 text-center ring-gray-200 font-semibold p-3 rounded">
                  <i className="fa fa-upload"></i> Upload Picture
                </div>
              </label>
              <input
                type="file"
                accept="image/*"
                id="file-upload"
                ref={fileInput}
                hidden
                name="fileUpload"
                onChange={(e) => {
                  // console.log(fileInput);
                  // console.log(e.target.files[0]);
                  setDocuments(e.target.files[0]);
                }}
              />
            </div>
          </div>
          <div className="my-2 p-6 col-span-2 md:col-span-1 min-w-40">
            <label className="text-lg font-semibold">
              Upload an audio of you reading the following text
            </label>
            <p className="p-4 mt-2 border-2 rounded bg-gray-100 text-2xl">
              老板，我 经常 来 这里 吃饭， 您 看 能 不能 便宜 一点儿
              ？你每天都在家干什么？抖 音 是 现在 中国 年轻人 中 最 流行 的 短视
              频从前 有 个人 养 了 很 多 羊
            </p>
            <label
              htmlFor="audioFile"
              className="block mt-4 font-semibold text-lg  mb-2"
            >
              Upload Audio File
            </label>
            {/* <input required accept="audio/*" id="audioFile" type="file" /> */}
            <input
              type="file"
              accept="audio/*"
              id="audioFile"
              ref={audioFile}
              // hidden
              onChange={(e) => {
                // console.log(audioFile);
                setAudio(e.target.files[0]);
              }}
            />
            <div className="mt-6">
              <button
                className="w-full shadow rounded-md border-2 border-gray-100 hover:border-gray-200 bg-green-500 hover:bg-green-400 text-white p-3 font-semibold"
                type="submit"
              >
                {btnTxt}
              </button>
              <div className="text-blue-500 text-right">
                <label className="cursor-pointer">
                  <Link to="/">Go back Home</Link>
                </label>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AgentRegister;
