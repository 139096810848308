import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AddCategory from "../../Components/AddCategory";
import { signout } from "../../Helpers/auth";
import { getData } from "../../Helpers/request";
import { toast } from "react-toastify";

function Category(props) {
  const [categories, setCategories] = useState([]);
  const [showAddCategory, setShowAddCategory] = useState(false);
  
  const getCategories = () => {
    getData("/vendor/categories")
      .then((data) => {
        if (data.error) {
          if (data.message === "Access Denied") {
            if (
              window.confirm(
                "Fetching data failed. Login again to verify your credentials"
              )
            ) {
              signout();
            }
          } else {
            toast(data.message);
          }
        } else {
          setCategories(data.categories);
        }
      })
      .catch((err) => console.log("err", err));
  };

  useEffect(getCategories, []);

  return (
    <div className="h-screen bg-gray-50 w-full">
      <AddCategory show={showAddCategory} close={() => setShowAddCategory(false)} />
      <div className="container mx-au to w-11/12 pt-10 px-5 h-full">
        <div className="container mx-auto pt-10 mt-8 px-8">
          <h1 className="float-left font-semibold text-2xl">Categories</h1>
          <button
            className="float-right cursor-pointer shadow bg-gray-800 rounded-2xl text-xs uppercase px-5 py-2 text-gray-50 font-semibold hover:bg-gray-700 hover:shadow-lg tracking-widest"
            onClick={() => {
              setShowAddCategory(!showAddCategory);
            }}
          >
            <i className="fa fa-plus"></i> Add Category
          </button>
          <div className="clear-both"></div>
        </div>
        <div className="container mx-auto pt-10 mt-8 px-8">
          <input
            className="w-full rounded shadow-md hover:shadow-lg p-4 ring-1 ring-gray-100 hover:ring-gray-200"
            placeholder="Search"
          />
        </div>
        <div className="container mx-auto mt-4 px-8">
          <table className="table min-w-96 rounded-lg shadow">
            <thead className="text-sm uppercase p-4 font-thin bg-blue-50 text-gray-400">
              <tr className="">
                <th className="p-3 font-medium text-left w-3/5">
                  Category Name
                </th>
                {/* <th className="p-3 font-medium w-1/5">Description</th> */}
                <th className="p-3 font-medium w-2/5 text-right">Options</th>
              </tr>
            </thead>
            <tbody>
              {categories.map((category) => {
                return (
                  <tr
                    key={category._id}
                    className="text-center bg-white border-b-2 border-gray-100 rounded-lg hover:bg-gray-50"
                  >
                    <td className="px-3 py-3 text-left relative">{category.name}</td>
                    {/* <td className="">{category.description}</td> */}
                    <td className="px-3 py-6 text-right">
                      <i className="fa fa-ellip sis-h"></i> &nbsp;
                      <Link to={`/category/${category._id}`}>Edit</Link>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Category;
