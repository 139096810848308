import React from "react";
import { Link } from "react-router-dom";

function HomePage(props) {
  return (
    <div className="h-screen bg-blue-50 w-full">
      <div className="w-full h-full flex flex-col justify-center align-center">
        <div className="container mx-auto p-6 shadow-lg bg-white w-auto h-auto">
          <h1 className="float-left font-bold text-4xl">
            <span className="text-lg text-gray-300">Welcome</span>
            <hr className="my-4" /> Kwanso Dashboard Login
          </h1>
          <div className="clear-both"></div>
          <div className="p-4 shadow-inner bg-gray-50 my-3">
            <h2 className="text-xl my-2">Freelancer (Agent + Merchant)</h2>
            <Link
              to="/freelancer/login"
              className="bg-gray-800 rounded-2xl text-xs uppercase px-3 py-2 text-gray-50 font-t hin"
            >
              <i className="fa fa-plus"></i> Login
            </Link>
            &nbsp;
            <Link
              to="/freelancer/register"
              className="bg-gray-800 rounded-2xl text-xs uppercase px-3 py-2 text-gray-50 font-t hin"
            >
              <i className="fa fa-plus"></i> Register
            </Link>
            {/* <h2 className="text-xl my-2">Agent</h2>
                    <Link to="/agent/login" className="bg-gray-800 rounded-2xl text-xs uppercase px-3 py-2 text-gray-50 font-t hin"><i className="fa fa-plus"></i> Login</Link>&nbsp;
                    <Link to="/agent/register" className="bg-gray-800 rounded-2xl text-xs uppercase px-3 py-2 text-gray-50 font-t hin"><i className="fa fa-plus"></i> Register</Link> */}
            <h2 className="text-xl my-2">Sales Manager</h2>
            <Link
              to="/manager/login"
              className="bg-gray-800 rounded-2xl text-xs uppercase px-3 py-2 text-gray-50 font-t hin"
            >
              <i className="fa fa-plus"></i> Login
            </Link>
            &nbsp;
            <Link
              to="/manager/register"
              className="bg-gray-800 rounded-2xl text-xs uppercase px-3 py-2 text-gray-50 font-t hin"
            >
              <i className="fa fa-plus"></i> Register
            </Link>
            <h2 className="text-xl my-2">Administrator</h2>
            <Link
              to="/admin/login"
              className="bg-gray-800 rounded-2xl text-xs uppercase px-3 py-2 text-gray-50 font-t hin"
            >
              <i className="fa fa-plus"></i> Login
            </Link>
            &nbsp;
            {/* <Link to="/admin/register" className="bg-gray-800 rounded-2xl text-xs uppercase px-3 py-2 text-gray-50 font-t hin "><i className="fa fa-plus"></i> Register</Link> */}
            <p className="pb-4"></p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomePage;
