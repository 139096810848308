import React from "react";
import { Link } from "react-router-dom";

function AddPart(props) {
  return (
    <div className="h-screen overflow-y-scroll bg-gray-50 w-full">
      <div className="container mx-a uto w-11/12 pt-10 px-5">
        <div className="container mx-auto pt-10 my-8 px-5">
          <Link to="/parts">
            <h1 className="float-left text-sm text-gray-600 font-bold uppercase">
              <i className="fa fa-arrow-left"></i> Parts
            </h1>
          </Link>
          <div className="clear-both"></div>
        </div>

        <div className="container mx-auto mt-10 px-5">
          <h1 className="float-left font-semibold text-2xl text-gray-600">Add Part</h1>
          <div className="clear-both"></div>
        </div>

        <div className="container mx-auto mt-4 px-5">
          <div className="grid grid-cols-12 h-60 gap-6">
            <div className="col-span-2">
              <div className="w-full p-3 bg-white rounded shadow ring-1 ring-gray-200">
                <h3 className="p-2 text-gray-600 uppercase font-bold text-sm">
                  Part Details
                </h3>
                <h3 className="p-2 text-gray-600 uppercase font-bold border-t-2 border-gray-100 text-sm">
                  Price
                </h3>
                <h3 className="p-2 text-gray-600 uppercase font-bold border-t-2 border-gray-100 text-sm">
                  Tags
                </h3>
              </div>
            </div>
            <div className="col-span-7">
              <div className="bg-white rounded ring-1 ring-gray-200 shadow-md p-3 mb-3">
                <h1 className="float-left text-xs text-gray-600 font-extrabold uppercase">
                  Car Details
                  <hr className="my-3 w-full border-gray-200" />
                </h1>
                <div className="w-full mt-4 text-gray-500 grid grid-cols-3 gap-4">
                  <div>
                    <select className="w-full shadow ring-1 ring-gray-200 border-2 border-gray-50 hover:border-gray-400 font-semibold p-3 rounded">
                      <option>Make</option>
                    </select>
                  </div>
                  <div>
                    <select className="w-full shadow ring-1 ring-gray-200 border-2 border-gray-50 hover:border-gray-400 font-semibold p-3 rounded">
                      <option>Model</option>
                    </select>
                  </div>
                  <div>
                    <select className="w-full shadow ring-1 ring-gray-200 border-2 border-gray-50 hover:border-gray-400 font-semibold p-3 rounded">
                      <option>Year</option>
                    </select>
                  </div>
                </div>
                
                <div className="w-full grid grid-cols-7 gap-4 my-4">
                  
                  <div className="col-span-3">
                    <select className="w-full shadow ring-1 ring-gray-200 border-2 border-gray-50 hover:border-gray-400 font-semibold p-3 rounded">
                      <option>Transmission Type</option>
                    </select>
                  </div>
                  {/* <div className="col-span-2">
                    <select className="w-full shadow ring-1 ring-gray-200 border-2 border-gray-50 hover:border-gray-400 font-semibold p-3 rounded">
                      <option>Fuel Type</option>
                    </select>
                  </div> */}
                {/* </div>
                <div className="w-full grid grid-cols-7 gap-4 my-4"> */}
                  
                  <div className="col-span-2">
                    <select className="w-full shadow ring-1 ring-gray-200 border-2 border-gray-50 hover:border-gray-400 font-semibold p-3 rounded">
                      <option>Body Type</option>
                    </select>
                  </div>
                  <div className="col-span-2">
                    <label htmlFor="file-upload">
                      <div className="w-full shadow ring-1 cursor-pointer text-white bg-blue-400 hover:bg-blue-500 text-center ring-gray-200 font-semibold p-3 rounded">
                        <i className="fa fa-upload"></i> Images
                      </div>
                    </label>
                    <input type="file" id="file-upload" hidden />
                  </div>
                </div>
                
                <h1 className="float-left my-3 text-xs text-gray-600 font-extrabold uppercase">
                  Part Details
                </h1>
                <div className="w-full my-4 text-gray-500 grid grid-cols-3 gap-4">
                  <div className="col-span-3">
                    <input className="w-full shadow ring-1 ring-gray-200 border-2 border-gray-50 hover:border-gray-400 font-semibold p-3 rounded" placeholder="Part Name"
                    />
                  </div>
                </div>
                <div>
                  <div className="mb-2 text-gray-700 text-sm uppercase font-semibold">
                    Description
                  </div>
                  <textarea
                    className="w-full shadow p-3 ring-1 ring-gray-200 border-2 border-gray-50 hover:border-gray-400 mb-4 rounded"
                    rows="5"
                    placeholder="Any other information of about the part here..."
                  ></textarea>
                </div>
              </div>

              <div className="bg-white rounded ring-1 ring-gray-200 shadow-md p-3 mb-3">
                <h1 className="float-left text-xs text-gray-600 font-extrabold uppercase">
                  Price
                  <hr className="my-3 border-gray-200" />
                </h1>
                <div>
                  <input
                    className="w-full shadow p-3 ring-1 ring-gray-200 border-2 border-gray-50 hover:border-gray-400 mb-4 rounded"
                    placeholder="Car Part's Price"
                  />
                </div>
              </div>

              <div className="bg-white rounded ring-1 ring-gray-200 shadow-md p-3 mb-3">
                <h1 className="float-left text-xs text-gray-600 font-extrabold uppercase">
                  Tags
                  <hr className="my-3 border-gray-200" />
                </h1>
                <div>
                  <textarea
                    className="w-full shadow p-3 ring-1 ring-gray-200 border-2 border-gray-50 hover:border-gray-400 mb-4 rounded"
                    placeholder="Car Part's Tags"
                    rows="3"
                  ></textarea>
                </div>
              </div>
            </div>
            <div className="col-span-3">
              <div className="w-full bg-white rounded shadow-md ring-1 ring-gray-200 p-3">
                <button className="w-full font-semibold capitalize rounded shadow-md bg-green-500 hover:bg-green-400 text-white py-3">
                  <i className="fa fa-save"></i> Save Part
                </button>
                <div className="w-full my-3 px-2">
                  <hr className="border-gray-100 my-3" />
                  <button className="float-right text-lg text-red-600 hover:text-red-400 font-semibold">
                    <i className="fa fa-trash text-lg"></i> Delete
                  </button>
                  <div className="clear-both"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddPart;
