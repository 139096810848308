import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
import { signout } from "../../Helpers/auth";
import { getData, postData } from "../../Helpers/request";
import { ToastContainer, toast } from "react-toastify";

function Orders(props) {
  const [orders, setOrders] = useState([]);
  const [count, setCount] = useState(0);
  const [filter, setFilter] = useState("");
  useEffect(() => {
    toast(count === 0 ? "Fetching Orders" : "Refreshing Orders");
    getOrders();
    console.log(count);
  }, [count]);

  const getOrders = () => {
    getData("/merchant/orders")
      .then((data) => {
        console.log(data);
        if (data.error) {
          if (data.message === "Access Denied") {
            toast(
              "Fetching data failed. Login again to verify your credentials"
            );
            signout();
          } else {
            toast.error(data.message);
          }
        } else {
          setOrders(data.orders);
          // setCount(data.orders.length);
        }
      })
      .catch((err) => console.log("err", err));
  };

  const updateOrder = (id, data) => {
    toast.info("Updating Order...");
    postData(`/merchant/order/${id}`, data)
      .then((e) => {
        console.log(e);
        toast.success(e.message);
        setCount(count + 1);
      })
      .catch((e) => console.error("err", e));
  };

  const filterTable = (e) => {
    setFilter(e);
    var x = document.getElementsByTagName("tr");
    for (var i in x) {
      if (!isNaN(parseInt(i))) {
        try {
          if (x[i].textContent.toLocaleLowerCase().indexOf(e.toLocaleLowerCase()) >= 0) {
            x[i].classList.remove('hidden')
          } else {
            x[i].classList.add('hidden')
          }
        } catch (e) {
          console.log(i, x[i]);
        }
      }
    }
    x[0].classList.remove('hidden')
  };

  return (
    <div className="h-screen overflow-auto bg-gray-50 w-full">
      <ToastContainer />
      <div className="container pt-10 px-2 md:px-5 h-full">
        <div className="container mx-auto pt-6 md:pt-10 mt-2 md:mt-8 px-2 md:px-8">
          <h1 className="font-semibold text-2xl">Orders</h1>
          <button className="py-1 px-2 my-1 rounded-lg bg-white border-2 border-gray-200" onClick={() => setCount(count + 1)}><span className="fa fa-refresh"></span> Refresh</button>
          {/* <div className="clear-both"></div> */}
        </div>
        <div className="container mx-auto pt-2 md:pt-10 mt-2 md:mt-8 px-2 md:px-8">
          <input
            className="w-full rounded shadow-md hover:shadow-lg p-4 ring-1 ring-gray-100 hover:ring-gray-200"
            placeholder="Search Order..."
            value={filter}
            onChange={(e) => filterTable(e.target.value)}
          />
        </div>
        <div className="container overflow-auto mx-auto pt-6 md:pt-10 mt-2 md:mt-8 px-2 md:px-8">
          <table className="table rounded-lg shadow">
            <thead className="text-sm uppercase p-4 font-thin bg-blue-50 text-gray-400">
              <tr className="">
                <th className="p-3 font-medium text-left w-2/6">Order ID</th>
                <th className="p-3 font-medium w-1/6">Customer</th>
                <th className="p-3 font-medium w-1/6 text-center">Qty/Cst</th>
                <th className="p-3 font-medium w-1/6 text-center">Status</th>
                <th className="p-3 font-medium w-1/6 text-center">
                  Update Status
                </th>
              </tr>
            </thead>
            <tbody>
              {orders.map((order) => (
                <tr
                  key={order._id}
                  className="text-center bg-white border-b-2 border-gray-100 rounded-lg hover:bg-gray-50"
                >
                  <td className="px-3 py-3 text-left relative">
                    <div className="flex flex-row flex-wrap">
                      <div
                        className="h-20 w-20 rounded-full bg-blue-200"
                        style={{
                          backgroundImage: `url(${order.productId.image})`,
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                          backgroundRepeat: "no-repeat",
                        }}
                      ></div>
                      <div className="my-auto text-gray-700 font-semibold px-2">
                        <span className="text-lg text-gray-800">
                          {order.productId.title}
                        </span>
                        <br />
                        <span className="text-sm font-bold text-gray-500">
                          {order.productId.category}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td className="">
                    {order.orderBy.name}
                    <br />
                    {order.orderBy.email}
                  </td>
                  <td className="p-2">
                    {order.orderQuantity} units - GHS{" "}
                    {parseInt(order.orderTotal).toFixed(2)}
                  </td>
                  <td className=" px-4 py-6 text-white text-right">
                    <span className="px-2 text-gray-600 font-semibold rounded bg-yellow-300 border-2 border-yellow-300 mb-2">
                      {order.orderStatus.replace(/_/g, " ")}
                    </span>{" "}
                    <br />
                    <span className="px-2 text-gray-600 font-semibold rounded mt-2 bg-blue-300 border-2 border-blue-300">
                      {order.paid ? "Paid" : "Not Paid"}
                    </span>
                  </td>
                  <td className="px-3 text-white py-6 text-left">
                    <button
                      onClick={(e) =>
                        updateOrder(order._id, { orderStatus: "cancelled" })
                      }
                      className="ml-1 mb-1 p-2 rounded bg-red-500"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={(e) =>
                        updateOrder(order._id, { orderStatus: "processing" })
                      }
                      className="ml-1 mb-1 p-2 rounded bg-blue-500"
                    >
                      Processing
                    </button>
                    <button
                      onClick={(e) =>
                        updateOrder(order._id, { orderStatus: "completed" })
                      }
                      className="ml-1 mb-1 p-2 rounded bg-green-500"
                    >
                      Complete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="h-20"></div>
      </div>
    </div>
  );
}

export default Orders;
