import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/storage";
import "firebase/compat/database";

var config = {
  apiKey: "AIzaSyAxFmJ3Cw-GYH3PfWeF5sy6mJlKkw92oEE",
  authDomain: "kwanso-1538a.firebaseapp.com",
  projectId: "kwanso-1538a",
  storageBucket: "kwanso-1538a.appspot.com",
  messagingSenderId: "69600036836",
  appId: "1:69600036836:web:d7c45e5c76b9e24694a9f1",
  measurementId: "G-RHRXXDYT80",
};

firebase.initializeApp(config);
// const auth = firebase.auth()

export default firebase;
