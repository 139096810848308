import "./tailwind.css";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { isAuth } from "./Helpers/auth";
import "react-toastify/dist/ReactToastify.css";

import Home from "./Pages/Home";
import HomePage from "./Pages/HomePage";
import Products from "./Pages/Products";
import ManagerProducts from "./Pages/Manager/Products";
import ManagerOrders from "./Pages/Manager/Orders";
import Parts from "./Pages/Parts";
import MerchantRegister from "./Pages/Register/MerchantRegister";
import AgentRegister from "./Pages/Register/AgentRegister";
// import AdminRegister from "./Pages/Register/AdminRegister";
import MerchantLogin from "./Pages/Login/MerchantLogin";
import AgentLogin from "./Pages/Login/AgentLogin";
import AdminLogin from "./Pages/Login/AdminLogin";
import Sidebar from "./Components/Sidebar";
import Adminbar from "./Components/Adminbar";
import Agentbar from "./Components/Agentbar";
import Managerbar from "./Components/Managerbar";
import AddProduct from "./Components/AddProduct";
import AddPart from "./Components/AddPart";
import UpdateProduct from "./Components/UpdateProduct";
import Store from "./Pages/Store";
import Category from "./Pages/Category";
import Orders from "./Pages/Orders";
import AgentUsers from "./Pages/Agent/Buyers";
import AdminProducts from "./Pages/Admin/Products";
import AgentOrders from "./Pages/Agent/Orders";
import AdminHome from "./Pages/Admin";
import ManagerLogin from "./Pages/Login/ManagerLogin";
import ManagerRegister from "./Pages/Register/ManagerRegister";
import AdminUsers from "./Pages/Admin/Users";
import AdminSettings from "./Pages/Admin/Settings";
import AdminOrders from "./Pages/Admin/AdminOrders";
import AddAgent from "./Pages/Admin/Components/AddAgent";
import AddManager from "./Pages/Admin/Components/AddManager";
import AddMerchant from "./Pages/Admin/Components/AddMerchant";
import ManagerHome from "./Pages/Manager/Home";
import ManagerAddProduct from "./Pages/Manager/AddProduct";
import Report from "./Pages/Manager/Report";
import AdminReport from "./Pages/Admin/Report";
import Categories from "./Pages/Admin/Categories";
import Chat from "./Components/Chat";

function App(props) {
  const routes = isAuth() ? true : false;
  const userType = isAuth() ? isAuth().type : "";

  return (
    <Router>
      <Chat />
      {routes && userType == "merchant" && (
        <Switch>
          <Route exact path="/products">
            <div className="grid grid-cols-12 gap-0 h-screen overflow-y-hidden">
              <div className="col-span-12 md:col-span-2 shadow border-2 border-gray-100">
                <Sidebar />
              </div>
              <div className="col-span-12 md:col-span-10">
                <Products />
              </div>
            </div>
          </Route>

          <Route exact path="/orders">
            <div className="grid grid-cols-12 gap-0 h-screen overflow-y-hidden">
              <div className="col-span-12 md:col-span-2 shadow border-2 border-gray-100">
                <Sidebar />
              </div>
              <div className="col-span-12 md:col-span-10">
                <Orders />
              </div>
            </div>
          </Route>

          <Route exact path="/categories">
            <div className="grid grid-cols-12 gap-0 h-screen overflow-y-hidden">
              <div className="col-span-12 md:col-span-2 shadow border-2 border-gray-100">
                <Sidebar />
              </div>
              <div className="col-span-12 md:col-span-10">
                <Category />
              </div>
            </div>
          </Route>

          <Route exact path="/product/add">
            <div className="grid grid-cols-12 gap-0 h-screen overflow-y-hidden">
              <div className="col-span-12 md:col-span-2 shadow border-2 border-gray-100">
                <Sidebar />
              </div>
              <div className="col-span-12 md:col-span-10">
                <AddProduct />
              </div>
            </div>
          </Route>

          <Route exact path="/product/:id">
            <div className="grid grid-cols-12 gap-0 h-screen overflow-y-hidden">
              <div className="col-span-12 md:col-span-2 shadow border-2 border-gray-100">
                <Sidebar />
              </div>
              <div className="col-span-12 md:col-span-10">
                <UpdateProduct />
              </div>
            </div>
          </Route>

          <Route exact path="/parts">
            <div className="grid grid-cols-12 gap-0 h-screen overflow-y-hidden">
              <div className="col-span-12 md:col-span-2 shadow border-2 border-gray-100">
                <Sidebar />
              </div>
              <div className="col-span-12 md:col-span-10">
                <Parts />
              </div>
            </div>
          </Route>

          <Route exact path="/store">
            <div className="md:grid md:grid-cols-12 gap-0 h-screen overflow-y-hidden">
              <div className="md:col-span-12 md:col-span-2 shadow border-2 border-gray-100">
                <Sidebar />
              </div>
              <div className="col-span-12 md:col-span-10 sm:fixed md:static w-full">
                <Store />
              </div>
            </div>
          </Route>

          <Route exact path="/part/add">
            <div className="grid grid-cols-12 gap-0 h-screen overflow-y-hidden">
              <div className="col-span-12 md:col-span-2 shadow border-2 border-gray-100">
                <Sidebar />
              </div>
              <div className="col-span-12 md:col-span-10">
                <AddPart />
              </div>
            </div>
          </Route>

          <Route path="/home">
            <div className="grid grid-cols-12 gap-0 h-screen overflow-y-hidden">
              <div className="col-span-12 md:col-span-2 shadow border-2 border-gray-100 sm:fi xed md:rel ative md:block">
                <Sidebar />
              </div>
              <div className=" col-span-12 md:col-span-10 w-full">
                <Home />
              </div>
            </div>
          </Route>
          <Route exact path="*">
            <Redirect to="/home" />
          </Route>
        </Switch>
      )}
      {routes && userType == "agent" && (
        <Switch>
          {/* <Route exact path="/products">
            <div className="grid grid-cols-12 gap-0 h-screen overflow-y-hidden">
              <div className="col-span-2 shadow border-2 border-gray-100">
                <Agentbar />
              </div>
              <div className="col-span-12 md:col-span-10">
                <Products />
              </div>
            </div>
          </Route> */}

          <Route exact path="/orders">
            <div className="grid grid-cols-12 gap-0 h-screen overflow-y-hidden">
              <div className="col-span-2 shadow border-2 border-gray-100">
                <Agentbar />
              </div>
              <div className="col-span-12 md:col-span-10">
                <AgentOrders />
              </div>
            </div>
          </Route>

          <Route path="/home">
            <div className="grid grid-cols-12 gap-0 h-screen overflow-y-hidden">
              <div className="col-span-2 shadow border-2 border-gray-100 sm:fi xed md:rel ative md:block">
                <Agentbar />
              </div>
              <div className=" col-span-12 md:col-span-10 w-full">
                <Home />
              </div>
            </div>
          </Route>
          <Route exact path="*">
            <Redirect to="/home" />
          </Route>
        </Switch>
      )}
      {routes && userType == "admin" && (
        <Switch>
          <Route path="/home">
            <div className="grid grid-cols-12 gap-0 h-screen overflow-y-hidden">
              <div className="col-span-2 shadow border-2 border-gray-100 sm:fi xed md:rel ative md:block">
                <Adminbar />
              </div>
              <div className=" col-span-12 md:col-span-10 w-full">
                <AdminHome />
              </div>
            </div>
          </Route>
          <Route path="/categories">
            <div className="grid grid-cols-12 gap-0 h-screen overflow-y-hidden">
              <div className="col-span-2 shadow border-2 border-gray-100 sm:fi xed md:rel ative md:block">
                <Adminbar />
              </div>
              <div className=" col-span-12 md:col-span-10 w-full">
                <Categories />
              </div>
            </div>
          </Route>
          <Route path="/orders">
            <div className="grid grid-cols-12 gap-0 h-screen overflow-y-hidden">
              <div className="col-span-2 shadow border-2 border-gray-100 sm:fi xed md:rel ative md:block">
                <Adminbar />
              </div>
              <div className=" col-span-12 md:col-span-10 w-full">
                <AdminOrders />
              </div>
            </div>
          </Route>
          <Route path="/products">
            <div className="grid grid-cols-12 gap-0 h-screen overflow-y-hidden">
              <div className="col-span-2 shadow border-2 border-gray-100 sm:fi xed md:rel ative md:block">
                <Adminbar />
              </div>
              <div className=" col-span-12 md:col-span-10 w-full">
                <AdminProducts />
              </div>
            </div>
          </Route>
          <Route path="/add/agent">
            <AddAgent />
          </Route>
          <Route path="/add/manager">
            <AddManager />
          </Route>
          <Route path="/add/merchant">
            <AddMerchant />
          </Route>
          <Route path="/settings">
            <div className="grid grid-cols-12 gap-0 h-screen overflow-y-hidden">
              <div className="col-span-2 shadow border-2 border-gray-100 sm:fi xed md:rel ative md:block">
                <Adminbar />
              </div>
              <div className=" col-span-12 md:col-span-10 w-full">
                <AdminSettings />
              </div>
            </div>
          </Route>
          <Route path="/users">
            <div className="grid grid-cols-12 gap-0 h-screen overflow-y-hidden">
              <div className="col-span-2 shadow border-2 border-gray-100 sm:fi xed md:rel ative md:block">
                <Adminbar />
              </div>
              <div className=" col-span-12 md:col-span-10 w-full">
                <AdminUsers />
              </div>
            </div>
          </Route>
          <Route path="/reports">
            <div className="grid grid-cols-12 gap-0 h-screen overflow-y-hidden">
              <div className="col-span-2 shadow border-2 border-gray-100 sm:fi xed md:rel ative md:block">
                <Adminbar />
              </div>
              <div className=" col-span-12 md:col-span-10 w-full">
                <AdminReport />
              </div>
            </div>
          </Route>
          <Route exact path="*">
            <Redirect to="/home" />
          </Route>
        </Switch>
      )}
      {routes && userType == "manager" && (
        <Switch>
          <Route exact path="/buyers">
            <div className="grid grid-cols-12 gap-0 h-screen overflow-y-hidden">
              <div className="col-span-2 shadow border-2 border-gray-100">
                <Managerbar />
              </div>
              <div className="col-span-12 md:col-span-10">
                <AgentUsers />
              </div>
            </div>
          </Route>
          <Route path="/home">
            <div className="grid grid-cols-12 gap-0 h-screen overflow-y-hidden">
              <div className="col-span-2 shadow border-2 border-gray-100 sm:fi xed md:rel ative md:block">
                <Managerbar />
              </div>
              <div className=" col-span-12 md:col-span-10 w-full">
                <ManagerHome />
              </div>
            </div>
          </Route>
          <Route path="/orders">
            <div className="grid grid-cols-12 gap-0 h-screen overflow-y-hidden">
              <div className="col-span-2 shadow border-2 border-gray-100 sm:fi xed md:rel ative md:block">
                <Managerbar />
              </div>
              <div className="col-span-12 md:col-span-10 w-full">
                <ManagerOrders />
              </div>
            </div>
          </Route>
          <Route path="/products">
            <div className="grid grid-cols-12 gap-0 h-screen overflow-y-hidden">
              <div className="col-span-2 shadow border-2 border-gray-100 sm:fi xed md:rel ative md:block">
                <Managerbar />
              </div>
              <div className=" col-span-12 md:col-span-10 w-full">
                <ManagerProducts />
              </div>
            </div>
          </Route>
          <Route path="/product/add">
            <div className="grid grid-cols-12 gap-0 h-screen overflow-y-hidden">
              <div className="col-span-2 shadow border-2 border-gray-100 sm:fi xed md:rel ative md:block">
                <Managerbar />
              </div>
              <div className=" col-span-12 md:col-span-10 w-full">
                <ManagerAddProduct />
              </div>
            </div>
          </Route>
          <Route path="/reports">
            <div className="grid grid-cols-12 gap-0 h-screen overflow-y-hidden">
              <div className="col-span-2 shadow border-2 border-gray-100 sm:fi xed md:rel ative md:block">
                <Managerbar />
              </div>
              <div className=" col-span-12 md:col-span-10 w-full">
                <Report />
              </div>
            </div>
          </Route>
          <Route exact path="*">
            <Redirect to="/home" />
          </Route>
        </Switch>
      )}
      {!routes && (
        <Switch>
          <Route exact path="/freelancer/login">
            <MerchantLogin />
          </Route>
          <Route exact path="/freelancer/register">
            <MerchantRegister />
          </Route>
          <Route exact path="/manager/login">
            <ManagerLogin />
          </Route>
          <Route exact path="/manager/register">
            <ManagerRegister />
          </Route>
          <Route exact path="/agent/login">
            <AgentLogin />
          </Route>
          <Route exact path="/agent/register">
            <AgentRegister />
          </Route>
          <Route exact path="/admin/login">
            <AdminLogin />
          </Route>
          {/* <Route exact path="/admin/register">
            <AdminRegister />
          </Route> */}
          <Route exact path="/home">
            <HomePage />
          </Route>
          <Route exact path="*">
            <Redirect to="/home" />
          </Route>
        </Switch>
      )}
    </Router>
  );
}

export default App;
