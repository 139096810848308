import React, { useEffect, useState } from "react";
import firebase from "../firebase";
import { toast, ToastContainer } from "react-toastify";
import { getData, postData } from "../Helpers/request";
import { isAuth } from "../Helpers/auth";
import __notify from "../assets/sound/notify.mp3";
import pop from "../assets/sound/pop.mp3";
function Chat(props) {
  const [show, setShow] = useState(false);
  const [showChat, setShowChat] = useState(false);
  const [chatUser, setChatUser] = useState(null);
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [users, setUsers] = useState([]);
  const [init, setInit] = useState(0);
  const [showFile, setShowFile] = useState(false);
  const [isBusy, setIsBusy] = useState(false);
  const filter = isAuth() ? isAuth()._id : null;
  const _notify = new Audio(__notify);
  const _pop = new Audio(pop);
  const db = firebase.database();
  // console.log(isAuth())
  useEffect(() => {
    if (isAuth()) {
      getUsers();
    }
  }, []);

  const getUsers = () => {
    setUsers([]);
    if (filter) {
      toast("Fetching Chat Users");
      getData("/users")
        .then((e) => {
          if (e.error) {
            toast.error(e.message);
          } else {
            // console.log(e.users)
            let _users = e.users.flatMap((u) =>
              u._id === filter
                ? []
                : [{ name: u.business ? u.business : u.name, _id: u._id }]
            );
            setUsers(_users);
          }
        })
        .catch((e) => console.error(e));

      db.ref(`/${filter}`).on("value", (data) => {
        getMessages();
        db.ref(`/new/${filter}`)
          .get()
          .then((_dd) => {
            let __d = _dd.val();
            // console.log(__d);
            if (__d) {
              playAudio();
              toast(
                `${__d.count} New message${__d.count > 1 ? "(s)" : ""} from ${
                  __d.name
                }`
              );
              // getMessages({name: __d.name, _id: __d.from})
              let d = { name: __d.name, _id: __d.from };
              setChatUser(d);
              getMessages(d);
            }
          });
      });
    } else {
      // alert('not logged in')
    }
  };

  const playAudio = () => {
    let promise = _notify.play();
    if (promise !== undefined) {
      promise
        .then((_) => {
          // Autoplay started!
        })
        .catch((error) => {
          setShowFile(true);
          console.log(error);
          // Autoplay was prevented.
          // Show a "Play" button so that user can start playback.
        });
    }
  };

  const startchat = (d) => {
    setMessages([]);
    makeBusy(true);
    db.ref(`/new/${filter}`).remove();
    db.ref(`/${filter}/${d._id}`).remove();
    setShowChat(true);
    setChatUser(d);
    getMessages(d);
  };

  const getMessages = (d = null) => {
    let _u = d === null ? chatUser : d;
    if (_u) {
      // toast("Fetching Chat ");
      getData(`/message/${chatCode(filter, _u._id, 0)}`)
        .then((d) => {
          if (d.error) {
            toast.error(d.message);
          } else {
            setMessages(d.messages);
            if (d.messages.at(-1).to === filter) {
              // playAudio()
            }
            var objDiv = document.getElementById("chatBox");
            objDiv.scrollTop = objDiv.scrollHeight;
          }
        })
        .catch((d) => console.log("err", d));
    }
  };

  const chatCode = (a, b, c) => {
    if (parseInt(a.charAt(c)) > parseInt(b.charAt(c))) {
      return a + b;
    } else if (parseInt(b.charAt(c)) > parseInt(a.charAt(c))) {
      return b + a;
    } else {
      return chatCode(a, b, c + 1);
    }
  };

  const sendMessage = () => {
    if (message.length <= 0) {
      toast.error("Error: Message field empty ");
    } else {
      let _cc = chatCode(filter, chatUser._id, 0);
      let msg = {
        message,
        from: filter,
        from_type: isAuth().type,
        to_type: "merchant",
        chat_code: _cc,
        to: chatUser._id,
        timestamp: Date.now(),
        name: isAuth().business ? isAuth().business : isAuth().name,
      };

      let _vmsg = msg;
      _vmsg.status = "sending";
      setMessages([...messages, _vmsg]);
      setMessage("");
      postData("/message", msg).then((d) => {
        if (!d.error) {
          //   send notification here
          _pop.play();
          db.ref(`/${msg.to}/${filter}`)
            .get()
            .then((d) => {
              let __counter = 1;
              if (d.exists()) {
                __counter = d.val().count + 1;
                console.log(d.val());
                db.ref(`/${msg.to}/${filter}`).set({
                  from: msg.from,
                  count: __counter,
                  name: isAuth().business ? isAuth().business : isAuth().name,
                  timestamp: Date.now(),
                });
              } else {
                db.ref(`/${msg.to}/${filter}`).set({
                  from: msg.from,
                  count: __counter,
                  name: isAuth().business ? isAuth().business : isAuth().name,
                  timestamp: Date.now(),
                });
              }
              db.ref(`/new/${msg.to}`).set({
                from: msg.from,
                count: __counter,
                name: isAuth().business ? isAuth().business : isAuth().name,
                timestamp: Date.now(),
              });
              getMessages();
            });
        } else {
          toast.error(d.message);
        }
      });
    }
  };

  const enableNotification = () => {
    // playAudio();
    setShowFile(false);
  };

  const makeBusy = (b) => {
    if (b === isBusy) {
    } else {
      postData("/manager/available", { isBusy: b })
        .then((d) => {
          console.log(d);
          setIsBusy(b);
        })
        .catch((e) => console.error(e));
    }
  };
  return isAuth() ? (
    <div className={`${isAuth() ? "" : "hidden"}`}>
      <ToastContainer />
      <div
        className={`${
          showFile
            ? "text-right bg-white rounded mt-2 fixed top-24 right-4 p-3 shadow z-50"
            : "hidden"
        }`}
      >
        <h3>Allow Notifications</h3>
        <button
          onClick={enableNotification}
          className="p-1 rounded text-sm font-bold text-white bg-blue-400"
        >
          Enable
        </button>
      </div>
      {isAuth() && (
        <div
          onClick={() => setShow(!show)}
          className={`${
            !show && filter && !showChat
              ? "fixed animate-pulse top-0 right-0 mr-8 mt-6 rounded-full h-14 w-14 bg-indigo-100 border-2 shadow hover:shadow-lg border-indigo-500 shadow flex justify-center align-center content-center z-50 cursor-pointer text-center"
              : "hidden"
          }`}
        >
          <h2 className="my-auto font-thin logo text-blue-600 text-xl">Chat</h2>
        </div>
      )}
      <div
        className={`${
          show && !showChat
            ? "fixed w-60 bg-blue-50 shadow border-2 border-blue-400 z-50 right-0 rounded-lg top-0 m-3"
            : "hidden"
        }`}
      >
        <div className="text-right">
          <button
            onClick={() => setShow(!show)}
            className="text-red-400 mx-2 p-1 font-extrabold"
          >
            x
          </button>
        </div>
        <div className="m-2">
          <button
            onClick={getUsers}
            className="p-1 mb-2 rounded text-white bg-blue-500"
          >
            <span className="fa fa-refresh"></span> Refresh Users
          </button>
          {isAuth().type === "manager" && (
            <div className="rounded grid grid-cols-2 my-2">
              <div
                onClick={() => makeBusy(true)}
                className={`ring-1 ring-gray-200 rounded-l p-2 text-center ${
                  isBusy ? "bg-gray-500 text-white" : ""
                }`}
              >
                Busy
              </div>
              <div
                onClick={() => makeBusy(false)}
                className={`ring-1 ring-gray-200 p-2 rounded-r text-center ${
                  !isBusy ? "bg-blue-500 text-white" : ""
                }`}
              >
                Free
              </div>
            </div>
          )}
          {/* <input
            className="w-full shadow-inner bg-blue-300 placeholder-gray-50 text-white focus:ring ring-blue-400 p-2 rounded"
            placeholder="Search by Username..."
          /> */}
        </div>
        <ul className="m-2 text-left max-h-60 overflow-auto">
          <li className="text-sm font-semibold text-blue-300 p-1 text -center ">
            {" "}
            {/* And then Click <span className="fa fa-arrow-down font-thin"></span> */}
          </li>
          {users.map((u) => (
            <li
              key={u._id}
              onClick={() => startchat(u)}
              className="p-1 border-t-2 border-blue-100 text-blue-600 hover:text-blue-400 font-semibold hover:bg-blue-100 capitalize cursor-pointer"
            >
              {u.title ? u.title : u.name}
            </li>
          ))}
        </ul>
      </div>

      <div
        className={`${
          showChat
            ? "fixed w-full md:w-96 h-full md:h-auto bg-white border-l-2 border-gray-200 shadow z-50 right-0 bottom-0 flex flex-col"
            : "hidden"
        }`}
      >
        <div className="bg-gray-100 border-b-2 shadow border-gray-200">
          <div className="m-1 flex">
            <h2 className="flex-grow p-2 font-semibold text-lg">
              {chatUser && chatUser.name}
            </h2>
            <button
              onClick={() => {
                setShowChat(false);
                makeBusy(false);
              }}
              className="text-red-400 font-bold rounded-full my-auto mr-3 pb-1 h-8 w-8 bg-red-100 border-2 border-red-300 text-lg"
            >
              x
            </button>
          </div>
        </div>
        <div className="flex-grow flex flex-col-reverse">
          <div className="flex flex-col bg-gray-50 shadow-inner">
            <div className="m-2">
              <input
                className="rounded-lg w-full shadow p-2"
                placeholder="Enter message here..."
                rows="2"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                onKeyPress={(e) => (e.code !== "Enter" ? null : sendMessage())}
              ></input>
            </div>
            <div className="mt-1 mx-2 mb-2">
              <button
                onClick={sendMessage}
                className="rounded hover:text-white text-green-500 hover:bg-green-500 transition duration-500 ease-in-out w-full bg-white px-2 py-1 my-auto center shadow-lg"
              >
                Send Message <span className="fa fa-arrow-right"></span>
              </button>
            </div>
          </div>
          <div
            id="chatBox"
            className="flex-grow shadow-inner bg-blue-50 h-96 text-black p-2 overflow-auto flex flex-col"
          >
            <div className="flex-grow"></div>
            {messages &&
              messages.map((m) => (
                <div
                  key={m.timestamp}
                  className={`p-2 w-auto min-w-24 mb-2 bg-white rounded shadow ${
                    m.from === filter ? "text-right" : "text-left"
                  }`}
                >
                  <span className="text-gray-800 font-semibold text-sm">
                    {m.message}
                  </span>
                  <br />
                  <div className="text-xs">
                    {new Date(m.timestamp).toUTCString()}{" "}
                    <span className="text-gray-400 float-right logo">
                      {m.status && m.status}{" "}
                    </span>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div></div>
  );
}

export default Chat;
