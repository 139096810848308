import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { isAuth } from "../../Helpers/auth";
import {
  getData,
  postData,
  uploadToFirebaseStorage,
} from "../../Helpers/request";
import { toast } from "react-toastify";
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

function MerchantRegister(props) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [province, setProvince] = useState("");
  const [category, setCategory] = useState([]);
  const [business, setBusiness] = useState("");
  const [documents, setDocuments] = useState("");
  const [btnTxt, setBtnTxt] = useState("Register");
  // const documents = React.createRef()
  const fileInput = React.createRef();

  const [categories, setCategories] = useState([]);
  const [verify, setVerify] = useState("");
  const h = useHistory();

  useEffect(() => {
    getData("/categories")
      .then((e) => {
        setCategories(
          e.categories.flatMap((c) => (c.enabled ? [c.title] : []))
        );
      })
      .catch((e) => console.error(e));
  }, []);
  const handleCheck = (k) => {
    if (category.includes(categories[k])) {
      setCategory(category.filter((i) => i !== categories[k]));
    } else {
      setCategory([...category, categories[k]]);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if(password !== verify) {
      toast.error('Error: Passwords do not match') 
    } else {
      setBtnTxt("Completing Registration... Please Wait");
    let files = [];
    // upload File
    files = documents.map((d) =>
      uploadToFirebaseStorage(`freelancer/certificates/`, d)
    );
    // let _push = uploadToFirebaseStorage(`freelancer/certificates/`, documents.current.files[0])
    // get URL
    let _file_paths = await Promise.all(files);
    // console.log(fileInput);

    // push data to api
    let data = {
      name,
      email,
      business,
      phone,
      password,
      province,
      category,
      certificate: _file_paths,
    };
    // console.log(data);
    // return;
    postData("/merchant/register", data)
      .then((data) => {
        console.log(data);
        toast(data.message);
        if (data.error) {
        } else {
          h.push("/freelancer/login");
        }
      })
      .catch((err) => {
        toast("Sorry: Registration Failed. Try Registering once more");
        console.log("err", err);
      })
      .finally((e) => setBtnTxt("Register"));
    }
  };

  return (
    <div className="w-full h-screen bg-gray-200 flex justify-center content-center">
      {isAuth() ? h.push("/dashboard") : null}
      <div className="m-auto bg-white shadow-lg rounded border-2 h-5/6 w-2/3 overflow-y-auto border-gray-100">
        <div className="w-75 font-bold text-gray-600 mb-3 text-xl">
          <h1 className="cursor-pointer">
            <Link
              to="/freelancer/register"
              className="float-left p-6 text-green-500"
            >
              Freelancer Signup&nbsp;
            </Link>
            <Link
              className="float-right p-6 bg-gray-100 text-gray-400"
              to="/freelancer/login"
            >
              &nbsp;Freelancer Login
            </Link>
          </h1>
        </div>
        <div className="border-t-2 border-gray-50 clear-both"></div>
        <form onSubmit={handleSubmit}>
          <div className="my-2 p-6">
            <div className="">
              <input
                className="w-full shadow rounded-md border-2 border-gray-100 hover:border-gray-200 p-3 font-semibold"
                placeholder="Your Business Name"
                value={business}
                onChange={(e) => setBusiness(e.target.value)}
                required
                autoComplete="business"
              />
            </div>
            <div className="mt-3">
              <input
                className="w-full shadow rounded-md border-2 border-gray-100 hover:border-gray-200 p-3 font-semibold"
                placeholder="Your Full Name"
                value={name}
                required
                onChange={(e) => setName(e.target.value)}
                autoComplete="name"
              />
            </div>
            <div className="mt-3">
              <input
                className="w-full shadow rounded-md border-2 border-gray-100 hover:border-gray-200 p-3 font-semibold"
                placeholder="Province"
                value={province}
                required
                onChange={(e) => setProvince(e.target.value)}
                autoComplete="province"
              />
            </div>
            <div className="mt-3 w-full shadow rounded-md border-2 border-gray-100 hover:border-gray-200 p-3 font-semibold">
              <label className="">Select your Products' categories</label>
              <div className="my-2">
                {categories.map((c, k) => (
                  <label key={k}>
                    <input
                      type="checkbox"
                      value={c}
                      name="category"
                      onChange={() => handleCheck(k)}
                    />
                    &nbsp; {c}&nbsp;&nbsp;&nbsp;
                  </label>
                ))}
              </div>
            </div>
            <div className="mt-3">
              <input
                className="w-full shadow rounded-md border-2 border-gray-100 hover:border-gray-200 p-3 font-semibold"
                placeholder="Phone Number"
                value={phone}
                required
                onChange={(e) => setPhone(e.target.value)}
                autoComplete="phone"
              />
            </div>
            <div className="mt-3">
              <input
                className="w-full shadow rounded-md border-2 border-gray-100 hover:border-gray-200 p-3 font-semibold"
                placeholder="Email"
                value={email}
                required
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                autoComplete="email"
              />
            </div>
            <div className="mt-3">
              <input
                className="w-full shadow rounded-md border-2 border-gray-100 hover:border-gray-200 p-3 font-semibold"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                required
                autoComplete="new-password"
              />
            </div>
            <div className="mt-3">
              <input
                className="w-full shadow rounded-md border-2 border-gray-100 hover:border-gray-200 p-3 font-semibold"
                placeholder="Verify Password"
                value={verify}
                required
                onChange={(e) => setVerify(e.target.value)}
                type="password"
                autoComplete="new-password"
              />
            </div>
            <div className="mt-3">
              <label htmlFor="doc">Your Business Certificate</label>
              <label htmlFor="file-upload">
                <div className="w-full shadow ring-1 cursor-pointer text-white bg-blue-400 hover:bg-blue-500 text-center ring-gray-200 font-semibold p-3 rounded">
                  <i className="fa fa-upload"></i> Upload Document(s)
                </div>
              </label>
              <input
                type="file"
                accept="application/pdf,image/*"
                id="file-upload"
                ref={fileInput}
                multiple
                hidden
                onChange={() =>
                  setDocuments(Object.values(fileInput.current.files))
                }
              />
            </div>
            <div className="mt-3">
              <button
                className="w-full shadow rounded-md border-2 border-gray-100 hover:border-gray-200 bg-green-500 hover:bg-green-400 text-white p-3 font-semibold"
                type="submit"
              >
                {btnTxt}
              </button>
              <div className="text-blue-500 text-right">
                <label className="cursor-pointer">
                  <Link to="/">Go back Home</Link>
                </label>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default MerchantRegister;
