import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { isAuth, signout } from "../Helpers/auth";
import "../tailwind.css";
import brand from "../assets/brand.png";

function Adminbar(props) {
  const history = useHistory();
  const [showMenu, setShowMenu] = useState(true);
  return (
    <div
      className={`${
        !showMenu && "h-screen z-50 w-full bg-black bg-opa ity-20"
      }`}
    >
      <div
        className="cursor-pointer sm:block md:hidden fixed m-2 left-0 top-0 rounded-lg text-center z-50"
        onClick={() => setShowMenu(!showMenu)}
      >
        <div className="bg-blue-600 m-2 rounded">
          <span className="fa fa-bars text-lg inline-block align-middle m-2 text-white"></span>
        </div>
      </div>
      <div
        className={`shadow-xl h-screen overflow-y-auto p-4 bg-white ${
          showMenu
            ? " w-full hidden md:block"
            : "w-full md:w-1/6 fixed left-0 top-0 z-40 block"
        } `}
      >
        <div className="h-16"></div>
        <div className="w-full rounded text-center py-3 pl-2 text-blue-500">
          <h2 className="text-3xl font-bold">
            <img src={brand} />
          </h2>
        </div>
        <div className="h-5"></div>
        <div className="w-full rounded py-3 pl-2 bg-blue-500">
          <h2 className="text-gray-200 font-semibold">
            {isAuth() && isAuth().name}
          </h2>
          <h3 className="text-gray-100 hover:text-gray-300 font-semibold text-sm">
            Admin Dashboard
          </h3>
        </div>
        <Link to="/home">
          <div className="w-full rounded hover:bg-blue-100 py-4 pl-2 my-2 uppercase">
            <h2 className="text-gray-500 text-sm font-semibold tracking-widest">
              <i className="fa fa-home"></i> &nbsp; Home
            </h2>
          </div>
        </Link>
        <Link to="/users">
          <div className="w-full rounded hover:bg-blue-100 py-4 pl-2 my-2 uppercase">
            <h2 className="text-gray-500 text-sm font-semibold tracking-widest">
              <i className="fa fa-users"></i> &nbsp; Users
            </h2>
          </div>
        </Link>
        <Link to="/categories">
          <div className="w-full rounded hover:bg-blue-100 py-4 pl-2 my-2 uppercase">
            <h2 className="text-gray-500 text-sm font-semibold tracking-widest">
              <i className="fa fa-shopping-basket"></i> &nbsp; Categories
            </h2>
          </div>
        </Link>
        <Link to="/orders">
          <div className="w-full rounded hover:bg-blue-100 py-4 pl-2 my-2 uppercase">
            <h2 className="text-gray-500 text-sm font-semibold tracking-widest">
              <i className="fa fa-tag"></i> &nbsp; Orders
            </h2>
          </div>
        </Link>
        <Link to="/products">
          <div className="w-full rounded hover:bg-blue-100 py-4 pl-2 my-2 uppercase">
            <h2 className="text-gray-500 text-sm font-semibold tracking-widest">
              <i className="fa fa-shopping-bag"></i> &nbsp; Products
            </h2>
          </div>
        </Link>
        <Link to="/reports">
          <div className="w-full rounded hover:bg-blue-100 py-4 pl-2 my-2 uppercase">
            <h2 className="text-gray-500 text-sm font-semibold tracking-widest">
              <i className="fa fa-line-chart"></i> &nbsp; Report
            </h2>
          </div>
        </Link>
        {/* <div className="w-full rounded hover:bg-blue-100 py-4 pl-2 my-2 uppercase">
          <h2 className="text-gray-500 text-sm font-semibold tracking-widest">
            <i className="fa fa-users"></i> &nbsp; Settings
          </h2>
        </div> */}
        {/* <Link to="/store">
          <div className="w-full rounded hover:bg-blue-100 py-4 pl-2 my-2 uppercase">
            <h2 className="text-gray-500 text-sm font-semibold tracking-widest">
              <i className="fa fa-cog"></i> &nbsp; My Store
            </h2>
          </div>
        </Link> */}
        <div
          className="w-full rounded hover:bg-blue-100 py-4 pl-2 my-2 uppercase cursor-pointer"
          onClick={() => {
            signout();
            history.push("/home");
            window.location.reload()
          }}
        >
          <h2 className="text-gray-500 text-sm font-semibold tracking-widest">
            <i className="fa fa-sign-out"></i> &nbsp; Logout
          </h2>
        </div>
      </div>
    </div>
  );
}

export default Adminbar;
