import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { signout } from "../../../Helpers/auth";
import { getData, postData } from "../../../Helpers/request";
import { toast } from "react-toastify";

function Report(props) {
  const [orders, setOrders] = useState([]);
  const [count, setCount] = useState(0);
  const [pending, setPending] = useState(0);
  const [cancelled, setCancelled] = useState(0);
  const [processing, setProcessing] = useState(0);
  const [completed, setCompleted] = useState(0);
  const [pendingPrice, setPendingPrice] = useState(0);
  const [cancelledPrice, setCancelledPrice] = useState(0);
  const [processingPrice, setProcessingPrice] = useState(0);
  const [completedPrice, setCompletedPrice] = useState(0);
  const [merchants, setMerchants] = useState([]);
  const [commission, setCommission] = useState(0);
  const [cat, setCat] = useState([]);
  const [catCost, setCatCost] = useState({});

  useEffect(() => {
    const _gd = async () => {
      getData("/manager/merchant")
        .then((d) => {
          if (d.merchants) {
            setMerchants(d.merchants);
          }
        })
        .catch((d) => console.error("err", d));
    };
    _gd();
    getOrders();
  }, [count]);

  const getOrders = () => {
    getData("/manager/orders")
      .then((data) => {
        // console.log(data);
        if (data.error) {
          if (data.message === "Access Denied") {
            if (
              window.confirm(
                "Fetching data failed. Login again to verify your credentials"
              )
            ) {
              signout();
            }
          } else {
            toast(data.message);
          }
        } else {
          setOrders(data.orders);
          // console.log(data.orders);
          let _p = 0;
          let _pp = 0;
          let _c = 0;
          let _cc = 0;
          let _pr = 0;
          let _prpr = 0;
          let _ca = 0;
          let _caca = 0;
          let __c = 0;
          data.orders.flatMap((o) => {
            __c += o.commission;
            if (o.orderStatus === "pending") {
              _pp += o.orderTotal;
              _p += 1;
            } else if (o.orderStatus === "completed") {
              _cc += o.orderTotal;
              _c += 1;
            } else if (o.orderStatus === "processing") {
              _prpr += o.orderTotal;
              _pr += 1;
            } else if (o.orderStatus === "cancelled") {
              _caca += o.orderTotal;
              _ca += 1;
            }

            return [];
          });
          let __categories = ["unassigned"];
          let cost = { unassigned: 0 };
          for (var o in data.orders) {
            if (data.orders[o].category) {
              if (__categories.includes(data.orders[o].category)) {
                cost[data.orders[o].category] += data.orders[o].orderTotal;
              } else {
                __categories.push(data.orders[o].category);
                cost[data.orders[o].category] = data.orders[o].orderTotal;
              }
            } else {
              cost.unassigned += data.orders[o].orderTotal;
            }
          }
          setCatCost(cost);
          setCat(__categories);

          setCommission(__c);
          setPending(_p);
          setPendingPrice(_pp.toFixed(2));
          setCompleted(_c);
          setCompletedPrice(_cc.toFixed(2));
          setCancelled(_ca);
          setCancelledPrice(_caca.toFixed(2));
          setProcessing(_pr);
          setProcessingPrice(_prpr.toFixed(2));

          setCount(data.orders.length);
        }
      })
      .catch((err) => console.log("err", err));
  };

  return (
    <div className="h-screen bg-gray-50 w-full overflow-auto">
      <div className="container mx-au to w-11/12 pt-10 px-5 h-full">
        <div className="container mx-auto pt-10 mt-8 px-8">
          <h1 className="font-semibold text-3xl">General Report</h1>
          <button onClick={() => setCount(count + 1)}>Refresh</button>
          {/* <div className="clear-both"></div> */}
        </div>
        <div className="container mx-auto pt-10 mt-8 px-8">
          <div className="container">
            <h1 className="font-bold text-2xl mb-4">Order Summary</h1>
            <div className="flex flex-wrap border-2 bg-gray-100 font-bold text-xl">
              <div className="flex-grow border-r-2 p-4">
                Total Commission Generated
              </div>
              <div className="p-4 font-bold">GHS {commission.toFixed(2)}</div>
            </div>
            <div className="h-16"></div>
            <div className="flex flex-wrap border-2 bg-gray-200 text-sm font-bold">
              <div className="flex-grow border-r-2 p-4">Title</div>
              <div className="p-4 border-r-2">Quantity</div>
              <div className="p-4">Price</div>
            </div>
            <div className="flex flex-wrap border-2 text-xl font-semibold bg-bl ue-200">
              <div className="flex-grow border-r-2 p-4">
                Total Orders Placed
              </div>
              <div className="p-4 border-r-2">{orders.length}</div>
              <div className="p-4">
                GHS{" "}
                {parseFloat(pendingPrice) +
                  parseFloat(cancelledPrice) +
                  parseFloat(completedPrice) +
                  parseFloat(processingPrice)}
              </div>
            </div>
            <div className="flex flex-wrap border-2 bg-gray-200">
              <div className="flex-grow border-r-2 p-4 font-bold">
                Pending Orders
              </div>
              <div className="p-4 border-r-2">{pending}</div>
              <div className="p-4">GHS {pendingPrice}</div>
            </div>
            <div className="flex flex-wrap border-2 bg-red-200">
              <div className="flex-grow border-r-2 p-4 font-bold">
                Cancelled Orders
              </div>
              <div className="p-4 border-r-2">{cancelled}</div>
              <div className="p-4">GHS {cancelledPrice}</div>
            </div>
            <div className="flex flex-wrap border-2 bg-blue-200">
              <div className="flex-grow border-r-2 p-4 font-bold">
                Processing Orders
              </div>
              <div className="p-4 border-r-2">{processing}</div>
              <div className="p-4">GHS {processingPrice}</div>
            </div>
            <div className="flex flex-wrap border-2 bg-green-200">
              <div className="flex-grow border-r-2 p-4 font-bold">
                Completed Orders
              </div>
              <div className="p-4 border-r-2">{completed}</div>
              <div className="p-4">GHS {completedPrice}</div>
            </div>
            <div className="h-16"></div>
            <div className="flex flex-wrap border-2 bg-gray-200 text-sm font-bold">
              <div className="flex-grow border-r-2 p-4">
                Sorted by categories
              </div>
            </div>
            {cat.map((m) => (
              <div className="flex flex-wrap border-2 bg-gray-50">
                <div className="flex-grow border-r-2 p-4 font-bold">{m}</div>
                <div className="p-4">GHS {catCost[m].toFixed(2)}</div>
              </div>
            ))}

            <div className="h-16"></div>
            <div className="flex flex-wrap border-2 bg-gray-100 font-bold text-xl">
              <div className="flex-grow border-r-2 p-4">Merchants</div>
              <div className="p-4 font-bold">{merchants.length}</div>
            </div>
            <div className="h-20"></div>
          </div>
        </div>
      </div>
      <div className="h-20"></div>
    </div>
  );
}

export default Report;
