import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { isAuth } from "../Helpers/auth";
import { getData, postData, uploadToFirebaseStorage } from "../Helpers/request";

function AddProduct(props) {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [minimumOrder, setMinimumOrder] = useState(1);
  const [discount, setDiscount] = useState(0);
  const [availableQuantity, setAvailableQuantity] = useState(0);
  const [productionCapacity, setProductionCapacity] = useState(0);
  const [port, setPort] = useState("");
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState("");

  const [paymentTerms, setPaymentTerms] = useState([
    { name: "Stripe", selected: false },
    { name: "PayPal", selected: false },
    { name: "Western Union", selected: false },
  ]);

  const [priceList, setPriceList] = useState([]);
  const [minQuantity, setMinQuantity] = useState("");
  const [maxQuantity, setMaxQuantity] = useState("");
  const [unitPrice, setUnitPrice] = useState("");

  const [images, setImages] = useState([]);
  const [video, setVideo] = useState([]);
  const fileInput = React.createRef();
  const videoInput = React.createRef();

  const [variant, setVariant] = useState([]);
  const [variantKey, setVariantKey] = useState("");

  const [quickDetails, setQuickDetails] = useState([]);
  const [detailTitle, setDetailTitle] = useState("");
  const [detailValue, setDetailValue] = useState("");

  const [btnText, setBtnText] = useState("Save Product");
  const addDetail = () => {
    if (true) {
      setQuickDetails([
        ...quickDetails,
        { title: detailTitle, description: detailValue },
      ]);
      setDetailTitle("");
      setDetailValue("");
    }
  };

  const handlePaymentTerms = (i) => {
    let _pt = paymentTerms;
    _pt[i].selected = !_pt[i].selected;
    setPaymentTerms(_pt);
  };
  // ${isAuth().business.replace(/ /g, "-")}
  // console.log(isAuth())
  const handleSubmit = async (e) => {
    e.preventDefault();
    setBtnText("Saving Product...");
    const paths = [];
    for (var key in images) {
      paths.push(uploadToFirebaseStorage(`merchant/products/`, images[key]));
    }
    var uploads = await Promise.all(paths);
    let _vUrl = "";
    if (video.length > 0) {
      _vUrl = await uploadToFirebaseStorage(`merchant/products/`, video[0]);
    } else {
      _vUrl = "";
    }
    let _v = {};
    for (var v in variant) {
      _v[Object.keys(variant[v])[0]] = Object.values(variant[v])[0];
    }
    let __p = {
      title,
      description,
      category,
      paymentTerms: paymentTerms.flatMap((i) => (i.selected ? [i.name] : [])),
      minimumOrder,
      discount,
      availableQuantity,
      productionCapacity,
      port,
      variations: _v,
      quickDetail: quickDetails,
      priceRanges: priceList.flatMap((i) => [
        {
          from: parseInt(i.min),
          to: parseInt(i.max),
          price: parseFloat(i.price),
        },
      ]),
      imageList: uploads,
      videoUrl: _vUrl,
    };
    // console.log(__p);

    // return;

    postData("/merchant/product", __p)
      .then((data) => console.log(data))
      .catch((data) => console.error("err", data))
      .finally((e) => setBtnText("Save Product"));
  };

  const handleVideoChange = (e) => {
    setVideo(videoInput.current.files);
  };

  const addPrice = () => {
    if (minQuantity < maxQuantity && unitPrice > 0) {
      let _x = { min: minQuantity, max: maxQuantity, price: unitPrice };
      setPriceList([...priceList, _x]);
      setMinQuantity("");
      setMaxQuantity("");
      setUnitPrice("");
    }
  };

  const removePrice = (k) => {
    let _pl = priceList.flatMap((i, index) => (index === k ? [] : [i]));
    setPriceList(_pl);
  };

  const addVariant = () => {
    setVariant([...variant, { [variantKey]: [] }]);
    setVariantKey("");
  };

  const addVariantObject = (n) => {
    // console.log(variant[n][Object.keys(variant[n])[0]]);
    let _v = variant;
    let _vn = variant[n];
    // return;
    // let _vn = _vn;
    _vn[Object.keys(variant[n])[0]].push({
      [document.getElementsByName(`${n}Title`)[0].value]:
        document.getElementsByName(`${n}Value`)[0].value,
      price: document.getElementsByName(`${n}Price`)[0].value,
    });
    _v[n] = _vn;
    setVariant(_v);
    document.getElementsByName(`${n}Title`)[0].value = "";
    document.getElementsByName(`${n}Value`)[0].value = "";
    document.getElementsByName(`${n}Price`)[0].value = "";
    let __v = variantKey;
    setVariantKey(`${variantKey} `);
    // setVariantKey(`${__v}`)
  };

  const removeVariantObject = (n, sn) => {
    console.log(n, sn);
    // let _v = variant[n][Object.keys(variant[n])[0]];
    // let _vn = _v.flatMap((n, i) => (parseInt(sn) === parseInt(i) ? [] : [n]));
    // _v = _vn;
    // setVariant(_v);
  };

  useEffect(() => {
    const _gd = async () => {
      getData("/categories")
        .then((d) => {
          setCategories(d.categories);
        })
        .catch((d) => console.error("err", d));
    };
    _gd();
  }, []);

  return (
    <div className="h-screen overflow-y-scroll bg-gray-50 w-full">
      <form onSubmit={handleSubmit}>
        <div className="container mx-a uto w-11/12 pt-6 px-5">
          <div className="container mx-auto pt-10 my-8 px-5">
            <Link to="/products">
              <h1 className="float-left text-sm text-gray-600 font-bold uppercase">
                <i className="fa fa-arrow-left"></i> Products
              </h1>
            </Link>
            <div className="clear-both"></div>
          </div>

          <div className="container mx-auto mt-10 px-5">
            <h1 className="float-left font-semibold text-2xl text-gray-600">
              Add Product
            </h1>
            <div className="clear-both"></div>
          </div>

          <div className="container mx-auto mt-4 px-5">
            <div className="grid grid-cols-12 h-60 gap-6">
              <div className="col-span-2">
                <div className="w-full p-3 bg-white rounded shadow ring-1 ring-gray-200">
                  <a href="#details">
                    <h3 className="p-2 text-gray-600 uppercase font-bold text-sm">
                      Details
                    </h3>
                  </a>
                  <a href="#price">
                    <h3 className="p-2 text-gray-600 uppercase font-bold border-t-2 border-gray-100 text-sm">
                      Price
                    </h3>
                  </a>
                  <a href="#media">
                    <h3 className="p-2 text-gray-600 uppercase font-bold border-t-2 border-gray-100 text-sm">
                      Media
                    </h3>
                  </a>
                  <a href="#Variations">
                    <h3 className="p-2 text-gray-600 uppercase font-bold border-t-2 border-gray-100 text-sm">
                      Variations
                    </h3>
                  </a>
                  <a href="#quick-details">
                    <h3 className="p-2 text-gray-600 uppercase font-bold border-t-2 border-gray-100 text-sm">
                      Quick Details
                    </h3>
                  </a>
                </div>
              </div>

              <div className="col-span-7" id="details">
                <div className="bg-white rounded ring-1 ring-gray-200 shadow-md p-3 mb-3">
                  <h1 className="float-left text-xs text-gray-600 font-extrabold uppercase">
                    Product Details
                    <hr className="my-3 w-full border-gray-200" />
                  </h1>
                  <div className="clear-left"></div>
                  <div className="w-full my-4">
                    <div className="">
                      <div className="mb-2 text-gray-700 text-sm uppercase font-semibold">
                        Product Name
                      </div>
                      <input
                        className="w-full shadow p-3 ring-1 ring-gray-200 border-2 border-gray-50 hover:border-gray-400 mb-4 rounded"
                        placeholder="Product Name"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="w-full my-4">
                    <div className="">
                      <div className="mb-2 text-gray-700 text-sm uppercase font-semibold">
                        Category
                      </div>
                      <select
                        className="w-full shadow p-3 ring-1 ring-gray-200 border-2 border-gray-50 hover:border-gray-400 mb-4 rounded text-black"
                        name="category"
                        value={category}
                        onChange={(e) => setCategory(e.target.value)}
                      >
                        <option>Select Category</option>
                        {categories &&
                          categories.map((c, _i) => (
                            <option key={_i} value={c.title}>
                              {c.title}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                  <div>
                    <div className="mb-2 text-gray-700 text-sm uppercase font-semibold">
                      Description
                    </div>
                    <textarea
                      className="w-full shadow p-3 ring-1 ring-gray-200 border-2 border-gray-50 hover:border-gray-400 mb-4 rounded"
                      rows="5"
                      placeholder="Any information about the Product here..."
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    ></textarea>
                  </div>
                  <div className="my-4 grid grid-cols-1 md:grid-cols-3 gap-4">
                    <div className="mt-2 md:mt-0">
                      <div className="mb-2 text-gray-700 text-sm uppercase font-semibold">
                        Minimum Order
                      </div>
                      <input
                        type="number"
                        min="1"
                        value={minimumOrder}
                        onChange={(e) => setMinimumOrder(e.target.value)}
                        className="w-full shadow p-3 ring-1 ring-gray-200 border-2 border-gray-50 hover:border-gray-400 mb-4 rounded"
                      />
                    </div>
                    <div className="mt-2 md:mt-0">
                      <div className="mb-2 text-gray-700 text-sm uppercase font-semibold">
                        Discount
                      </div>
                      <input
                        type="number"
                        min="0"
                        value={discount}
                        onChange={(e) => setDiscount(e.target.value)}
                        className="w-full shadow p-3 ring-1 ring-gray-200 border-2 border-gray-50 hover:border-gray-400 mb-4 rounded"
                      />
                    </div>
                    <div className="mt-2 md:mt-0">
                      <div className="mb-2 text-gray-700 text-sm uppercase font-semibold">
                        Available Quantity
                      </div>
                      <input
                        type="number"
                        min="0"
                        value={availableQuantity}
                        onChange={(e) => setAvailableQuantity(e.target.value)}
                        className="w-full shadow p-3 ring-1 ring-gray-200 border-2 border-gray-50 hover:border-gray-400 mb-4 rounded"
                      />
                    </div>
                  </div>
                  <div className="my-4 grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div className="mt-2 md:mt-0">
                      <div className="mb-2 text-gray-700 text-sm uppercase font-semibold">
                        Production Capacity
                      </div>
                      <input
                        type="number"
                        min="0"
                        value={productionCapacity}
                        onChange={(e) => setProductionCapacity(e.target.value)}
                        className="w-full shadow p-3 ring-1 ring-gray-200 border-2 border-gray-50 hover:border-gray-400 mb-4 rounded"
                      />
                    </div>
                    <div className="mt-2 md:mt-0">
                      <div className="mb-2 text-gray-700 text-sm uppercase font-semibold">
                        Port
                      </div>
                      <select
                        className="w-full shadow p-3 ring-1 ring-gray-200 border-2 border-gray-50 hover:border-gray-400 mb-4 rounded"
                        name="port"
                        value={port}
                        onChange={(e) => setPort(e.target.value)}
                      >
                        <option>Select Port</option>
                        <option value="Shenzhen, China">Shenzhen, China</option>
                        <option value="Shenzhen, China">Shanghai, China</option>
                      </select>
                    </div>
                  </div>
                  <div className="my-4 p-2 border-2 rounded">
                    <div className="mb-2 text-gray-700 text-sm uppercase font-semibold">
                      Payment Terms
                    </div>
                    {paymentTerms.map((p, i) => (
                      <label className="m-2" key={i}>
                        <input
                          type="checkbox"
                          onChange={(e) => handlePaymentTerms(i)}
                        />
                        {p.name}
                      </label>
                    ))}
                  </div>
                  <div id="price"></div>
                </div>

                <div className="bg-white rounded ring-1 ring-gray-200 shadow-md p-3 mb-3">
                  <h1 className="float-left text-xs text-gray-600 font-extrabold uppercase">
                    Price
                    <div className="clear-right"></div>
                    <hr className="my-3 border-gray-200" />
                  </h1>

                  <div>
                    <table className="w-full mt-3">
                      <thead className="bg-blue-400 text-white">
                        <tr>
                          <td
                            className="p-2 border-r-2 border-white"
                            colSpan="4"
                          >
                            Quantity
                          </td>
                          <td
                            className="p-2 border-r-2 border-white"
                            colSpan=""
                          >
                            Price
                          </td>
                          <td className="p-2">...</td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="p-2 border-2 text-center">From</td>
                          <td className="p-2 border-2 text-center">
                            <input
                              className="shadow ring-1 ring-gray-200 border-2 border-gray-50 hover:border-gray-400 w-full rounded"
                              placeholder="Quantity"
                              value={minQuantity}
                              type="number"
                              onChange={(e) => setMinQuantity(e.target.value)}
                            />
                          </td>
                          <td className="p-2 border-2 text-center">to</td>
                          <td className="p-2 border-2 text-center">
                            <input
                              className="shadow ring-1 ring-gray-200 border-2 border-gray-50 hover:border-gray-400 w-full rounded"
                              type="number"
                              placeholder="Quantity"
                              value={maxQuantity}
                              onChange={(e) => setMaxQuantity(e.target.value)}
                            />
                          </td>
                          <td className="p-2 border-2 text-center">
                            <input
                              className="shadow ring-1 ring-gray-200 border-2 border-gray-50 hover:border-gray-400 w-full rounded"
                              placeholder="GHS 0.00"
                              value={unitPrice}
                              type="number"
                              onChange={(e) => setUnitPrice(e.target.value)}
                            />
                          </td>
                          <td className="p-2 border-2 text-center">
                            <button
                              type="button"
                              onClick={addPrice}
                              className="rounded-lg border-2 border-blue-500 px-2 text-sm text-blue-500"
                            >
                              <span className="fa fa-plus"></span> Price
                            </button>
                          </td>
                        </tr>
                        {priceList &&
                          priceList.map((i, k) => (
                            <tr key={k}>
                              <td className="p-2 border-2 text-center" key={k}>
                                From
                              </td>
                              <td className="p-2 border-2 text-center">
                                <span className="text-lg font-bold">
                                  {i.min}
                                </span>
                              </td>
                              <td className="p-2 border-2 text-center">to</td>
                              <td className="p-2 border-2 text-center">
                                <span className="text-lg font-bold">
                                  {i.max}
                                </span>
                              </td>
                              <td className="p-2 border-2 text-center">
                                <span className="text-lg font-bold">
                                  {parseFloat(i.price).toFixed(2)}
                                </span>
                              </td>
                              <td
                                className="p-0 border-2 text-center transition ease-in-out duration-200 bg-red-400 hover:bg-white text-white hover:text-red-600"
                                colSpan="2"
                              >
                                <button
                                  className="h-full w-full"
                                  onClick={() => removePrice(k)}
                                >
                                  x
                                </button>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                  <div id="media"></div>
                </div>

                <div className="bg-white rounded ring-1 ring-gray-200 shadow-md p-3 mb-3">
                  <h1 className="float-left text-xs text-gray-600 font-extrabold uppercase">
                    Media
                    <hr className="my-3 w-full border-gray-200" />
                  </h1>
                  <div className="clear-left"></div>
                  <div className="grid grid-cols-2 gap-4">
                    <div className="col-span-1">
                      <label htmlFor="file-upload">
                        <div className="w-full shadow ring-1 cursor-pointer text-white bg-blue-400 hover:bg-blue-500 text-center ring-gray-200 font-semibold p-3 rounded">
                          <i className="fa fa-upload"></i> Upload Image(s)
                        </div>
                      </label>
                      <input
                        type="file"
                        accept="image/*"
                        id="file-upload"
                        ref={fileInput}
                        multiple
                        hidden
                        onChange={() =>
                          setImages(Object.values(fileInput.current.files))
                        }
                      />
                    </div>
                    <div className="col-span-1">
                      <label htmlFor="video-upload">
                        <div className="w-full shadow ring-1 cursor-pointer text-white bg-green-400 hover:bg-green-500 text-center ring-gray-200 font-semibold p-3 rounded">
                          <i className="fa fa-upload"></i> Upload Video
                        </div>
                      </label>
                      <input
                        type="file"
                        accept="video/*"
                        id="video-upload"
                        ref={videoInput}
                        hidden
                        onChange={handleVideoChange}
                      />
                    </div>
                  </div>

                  <div className="bg-white rounded ring-1 ring-gray-200 shadow-md p-3 my-3">
                    <h1 className="float-left text-xs text-gray-600 font-extrabold uppercase">
                      Image(s) Preview
                    </h1>
                    <div className="clear-both"></div>
                    {images.length > 0 && (
                      <div className="grid grid-cols-1 md:grid-cols-3 gap-0">
                        {images.map((image, key) => (
                          <div key={key}>
                            <img
                              alt=""
                              key={key}
                              className="bg-local inline-block rounded m-1"
                              src={URL.createObjectURL(image)}
                            />
                          </div>
                        ))}
                      </div>
                    )}
                  </div>

                  <div className="bg-white rounded ring-1 ring-gray-200 shadow-md p-3 my-3">
                    <h1 className="float-left text-xs text-gray-600 font-extrabold uppercase">
                      Video Preview
                    </h1>
                    <div className="clear-both"></div>
                    {video.length > 0 && (
                      <div>
                        <video controls>
                          <source src={URL.createObjectURL(video[0])} />
                        </video>
                      </div>
                    )}
                  </div>
                  <div id="Variations"></div>
                </div>

                <div className="bg-white rounded ring-1 ring-gray-200 shadow-md p-3 mb-3">
                  <div className="flex">
                    <div>
                      <h1 className="text-xs text-gray-600 font-extrabold uppercase">
                        Variations
                        <hr className="my-3 border-gray-200" />
                      </h1>
                    </div>
                    <div className="flex-grow flex flex-row-reverse p-2">
                      <button
                        onClick={addVariant}
                        type="button"
                        className="rounded-lg border-2 border-blue-500 px-2 text-sm text-blue-500"
                      >
                        <span className="fa fa-plus"></span> Variation
                      </button>
                      <input
                        className="mr-2 rounded border-b-2 my-auto hover:border-gray-300 w-auto"
                        placeholder="Add Variant Name Here"
                        name={variantKey}
                        onChange={(e) => setVariantKey(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="clear-both"></div>
                  {/* {console.log(variant)} */}
                  {variant &&
                    variant.map((i, k) => (
                      <div key={k} className="p-2 rounded border-2">
                        <div>
                          <h2 className="float-left text-gray-600 font-semibold capitalize">
                            {Object.keys(i)[0]}
                          </h2>
                          <div className="clear-both"></div>
                        </div>

                        <table className="w-full mt-3">
                          <thead className="bg-blue-400 text-white">
                            <tr>
                              <td
                                className="p-2 border-r-2 border-white"
                                colSpan="2"
                              >
                                Property Title
                              </td>
                              <td
                                className="p-2 border-r-2 border-white"
                                colSpan="2"
                              >
                                Value
                              </td>
                              <td
                                className="p-2 border-r-2 border-white"
                                colSpan="2"
                              >
                                Price
                              </td>
                              <td className="p-2" colSpan="2">
                                ...
                              </td>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td
                                className="p-2 border-2 text-center"
                                colSpan="2"
                              >
                                <input
                                  className="shadow ring-1 ring-gray-200 border-2 border-gray-50 hover:border-gray-400 w-full rounded"
                                  placeholder="Title"
                                  name={`${k}Title`}
                                />
                              </td>
                              <td
                                className="p-2 border-2 text-center"
                                colSpan="2"
                              >
                                <input
                                  className="shadow ring-1 ring-gray-200 border-2 border-gray-50 hover:border-gray-400 w-full rounded"
                                  placeholder="Value"
                                  name={`${k}Value`}
                                />
                              </td>
                              <td
                                className="p-2 border-2 text-center"
                                colSpan="2"
                              >
                                <input
                                  className="shadow ring-1 ring-gray-200 border-2 border-gray-50 hover:border-gray-400 w-full rounded"
                                  placeholder="Price"
                                  type="number"
                                  name={`${k}Price`}
                                />
                              </td>
                              <td
                                className="p-2 border-2 text-center"
                                colSpan="2"
                              >
                                <button
                                  type="button"
                                  className="rounded-lg border-2 border-blue-500 px-2 text-sm text-blue-500"
                                  onClick={() => addVariantObject(k)}
                                >
                                  <span className="fa fa-plus"></span> Property
                                </button>
                              </td>
                            </tr>
                            {i[Object.keys(i)[0]].map((rows, index) => (
                              <tr key={index}>
                                <td
                                  className="p-2 border-2 text-center font-semibold capitalize"
                                  colSpan="2"
                                >
                                  {Object.keys(rows)[0]}
                                </td>
                                <td
                                  className="p-2 border-2 text-center font-semibold capitalize"
                                  colSpan="2"
                                >
                                  {Object.values(rows)[0]}
                                </td>
                                <td
                                  className="p-2 border-2 text-center font-semibold capitalize"
                                  colSpan="2"
                                >
                                  GHS {parseInt(rows.price).toFixed(2)}
                                </td>
                                <td
                                  className="p-0 border-2 text-center transition ease-in-out duration-200 bg-red-400 hover:bg-white text-white hover:text-red-600"
                                  colSpan="2"
                                >
                                  <button
                                    type="button"
                                    className="h-full w-full"
                                    onClick={() =>
                                      removeVariantObject(k, index)
                                    }
                                  >
                                    x
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    ))}
                </div>

                <div className="bg-white rounded ring-1 ring-gray-200 shadow-md p-3 mb-3">
                  <div className="flex">
                    <div className="flex-none">
                      <h1 className="text-xs text-gray-600 font-extrabold uppercase">
                        Quick Details
                        <hr className="my-3 border-gray-200" />
                      </h1>
                    </div>
                  </div>

                  <div className="clear-both"></div>
                  <div className="p-2 rounded border-2">
                    <div>
                      <h2 className="float-left text-gray-600 font-semibold capitalize">
                        Details
                      </h2>
                      <div className="clear-both"></div>
                    </div>

                    <table className="w-full mt-3">
                      <thead className="bg-blue-400 text-white">
                        <tr>
                          <td
                            className="p-2 border-r-2 border-white"
                            colSpan="2"
                          >
                            Title
                          </td>
                          <td
                            className="p-2 border-r-2 border-white"
                            colSpan="2"
                          >
                            Value
                          </td>
                          <td className="p-2" colSpan="2">
                            ...
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="p-2 border-2 text-center" colSpan="2">
                            <input
                              className="shadow ring-1 ring-gray-200 border-2 border-gray-50 hover:border-gray-400 w-full rounded"
                              value={detailTitle}
                              name="detailTitle"
                              onChange={(e) => setDetailTitle(e.target.value)}
                              placeholder="Title"
                            />
                          </td>
                          <td className="p-2 border-2 text-center" colSpan="2">
                            <input
                              className="shadow ring-1 ring-gray-200 border-2 border-gray-50 hover:border-gray-400 w-full rounded"
                              placeholder="Value"
                              value={detailValue}
                              name="detailValue"
                              onChange={(e) => setDetailValue(e.target.value)}
                            />
                          </td>
                          <td className="p-2 border-2 text-center" colSpan="2">
                            <button
                              type="button"
                              onClick={addDetail}
                              className="rounded-lg border-2 border-blue-500 px-2 text-sm text-blue-500"
                            >
                              <span className="fa fa-plus"></span> Detail
                            </button>
                          </td>
                        </tr>
                        {quickDetails.map((d, dk) => (
                          <tr
                            key={dk}
                            className="text-center text-lg font-bold"
                          >
                            <td className="p-2 border-2" colSpan="2">
                              {d.title}
                            </td>
                            <td className="p-2 border-2" colSpan="2">
                              {d.description}
                            </td>
                            <td
                              className="p-0 border-2 text-center transition ease-in-out duration-200 bg-red-400 hover:bg-white text-white hover:text-red-600"
                              colSpan="2"
                            >
                              <button className="h-full w-full">x</button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div id="quick-details"></div>
              </div>

              <div className="col-span-3">
                <div className="w-full bg-white rounded shadow-md ring-1 ring-gray-200 p-3">
                  <button
                    className="w-full font-semibold capitalize rounded shadow-md bg-green-500 hover:bg-green-400 text-white py-3"
                    type="submit"
                  >
                    <i className="fa fa-save"></i> {btnText}
                  </button>
                  <div className="w-full my-3 px-2">
                    <hr className="border-gray-100 my-3" />
                    <button className="float-right text-lg text-red-600 hover:text-red-400 font-semibold">
                      <i className="fa fa-trash text-lg"></i> Clear
                    </button>
                    <div className="clear-both"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default AddProduct;
