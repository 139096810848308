import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { signout } from "../../Helpers/auth";
import { getData } from "../../Helpers/request";
import { ToastContainer, toast } from "react-toastify";

function Products(props) {
  const [products, setProducts] = useState([]);
  const [count, setCount] = useState();
  useEffect(() => {
    getProducts();
  }, [count]);

  const getProducts = () => {
    toast.info("Fetching Products. Please Wait...");
    getData("/merchant/product")
      .then((data) => {
        // console.log(data);
        if (data.error) {
          if (data.message === "Access Denied") {
            if (
              window.confirm(
                "Fetching data failed. Login again to verify your credentials"
              )
            ) {
              signout();
            }
          } else {
            toast.error(data.message);
          }
        } else {
          setProducts(data.products);

          console.log(data.products);
          // setCount(data.products.length)
        }
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(err.message);
      });
  };
  return (
    <div className="h-screen overflow-auto bg-gray-50 w-full">
      <ToastContainer />
      <div className="container pt-10 px-2 md:px-5 h-full">
        <div className="container mx-auto pt-8 md:pt-10 mt-2 md:mt-8 px-2 md:px-8">
          <h1 className="float-left font-semibold text-2xl">Products</h1>
          <Link
            to="/product/add"
            className="float-right shadow rounded-2xl text-xs uppercase px-3 py-2 text-blue-500 border-2 border-blue-500 font-semibold transition ease-in-out duration-200 hover:bg-blue-500 hover:text-white"
          >
            <i className="fa fa-plus"></i> Add Product
          </Link>
          <div className="clear-both"></div>
        </div>
        <div className="container mx-auto pt-6 md:pt-10 mt-2 md:mt-8 px-2 md:px-8">
          <input
            className="w-full rounded shadow-md hover:shadow-lg p-4 ring-1 ring-gray-100 hover:ring-gray-200"
            placeholder="Search Product..."
          />
        </div>
        <div className="container mx-auto overflow-auto pt-6 md:pt-10 mt-2 md:mt-8 px-2 md:px-8">
          <table className="table min-w-96 rounded-lg shadow">
            <thead className="text-sm uppercase p-4 font-thin bg-blue-50 text-gray-400">
              <tr className="">
                <th className="p-3 font-medium text-left w-2/5">Product</th>
                {/* <th className="p-3 font-medium w-1/5">Description</th> */}
                <th className="p-3 font-medium w-1/5 text-right">Price</th>
                <th className="p-3 font-medium w-1/5 text-right">Options</th>
              </tr>
            </thead>
            <tbody>
              {products.map((product) => {
                let lowest_price = 0,
                  highest_price = 0;
                product.priceRanges.map((p, i) => {
                  if (i === 0) {
                    lowest_price = highest_price = p.price;
                  } else {
                    if (lowest_price > p.price) {
                      lowest_price = p.price;
                    }
                    if (highest_price < p.price) {
                      highest_price = p.price;
                    }
                  }
                });
                return (
                  <tr
                    key={product._id}
                    className="text-center bg-white border-b-2 border-gray-100 rounded-lg hover:bg-gray-50"
                  >
                    {/* {console.log(product.title, product.image)} */}
                    <td className="px-3 py-3 text-left relative">
                      <div className="flex flex-row flex-wrap">
                        <div
                          className="h-20 w-20 rounded-full bg-blue-200"
                          style={{
                            backgroundImage: `url(${product.image})`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                          }}
                        ></div>
                        <div className="my-auto text-gray-700 font-semibold px-2">
                          <span className="text-lg text-gray-800">
                            {product.title}
                          </span>
                          <br />
                          <span className="text-sm font-bold text-gray-500">
                            {JSON.stringify(product.category)}
                          </span>
                        </div>
                      </div>
                    </td>
                    {/* <td className="">{product.description}</td> */}
                    <td className=" px-3 py-6 text-green-600 text-right">
                      GHS {parseInt(lowest_price).toFixed(2)} -{" "}
                      {parseInt(highest_price).toFixed(2)}
                    </td>
                    <td className="px-3 py-6 text-right">
                      <i className="fa fa-ellip sis-h"></i> &nbsp;
                      <Link to={`/product/${product._id}`}>Edit</Link>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="h-24"></div>
      </div>
    </div>
  );
}

export default Products;
