import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { signout } from "../../../Helpers/auth";
import { getData, postData } from "../../../Helpers/request";
import { ToastContainer, toast } from 'react-toastify';

function Orders(props) {
  const [orders, setOrders] = useState([]);
  const [count, setCount] = useState(0);

  useEffect(() => {
    getOrders();
  }, [count]);

  const getOrders = () => {
    toast.info('Fetching Orders. Please Wait...')
    getData("/agent/orders")
      .then((data) => {
        // console.log(data);
        if (data.error) {
          if (data.message === "Access Denied") {
            if (
              window.confirm(
                "Fetching data failed. Login again to verify your credentials"
              )
            ) {
              signout();
            }
          } else {
            toast.error(data.message);
          }
        } else {
          setOrders(data.orders);
          console.log(data.orders);
          setCount(data.orders.length);
        }
      })
      .catch((err) => console.log("err", err));
  };

  const createCommission = (id) => {
    let _cVal = parseFloat(document.getElementById(id).value)
    if (_cVal > 0 && _cVal < 100) {
      postData(`/agent/order/commission/${id}`, { percent: _cVal })
        .then((e) => {
          console.log(e)
          toast.info(e.order.message)
          setCount(count++)
        })
        .catch((e) => console.error("err", e));
    } else {
      toast.error('Commission Percentage has to be a numberic value greater than 0 and less than 100')
    }
  };

  return (
    <div className="h-screen bg-gray-50 w-full overflow-auto">
      <ToastContainer  />
      <div className="container mx-au to w-full pt-10 px-5 h-full">
        <div className="container mx-auto pt-10 mt-8 px-8">
          <h1 className="font-semibold text-2xl">Orders Assigned to You</h1>
          <button onClick={() => setCount(count + 1)}>Refresh</button>
          {/* <div className="clear-both"></div> */}
        </div>
        <div className="container mx-auto pt-10 mt-8 px-8">
          <input
            className="w-full rounded shadow-md hover:shadow-lg p-4 ring-1 ring-gray-100 hover:ring-gray-200"
            placeholder="Search Order..."
          />
        </div>
        <div className="container mx-auto my-4 px-8">
          <table className="table-fixed w-full rounded-lg shadow">
            <thead className="text-sm uppercase p-4 font-thin bg-blue-50 text-gray-400">
              <tr className="">
                <th className="p-3 font-medium text-center w-2/5">Ordered Product</th>
                <th className="p-3 font-medium w-1/5">Customer</th>
                <th className="p-3 font-medium w-1/5 text-center">Qty/Cst/Cmsn</th>
                <th className="p-3 font-medium w-1/5 text-center">Status</th>
                <th className="p-3 font-medium w-1/5 text-center">
                  Update Status
                </th>
              </tr>
            </thead>
            <tbody>
              {orders.map((order) => {
                return (
                  <tr
                    key={order._id}
                    className="text-center bg-white border-b-2 border-gray-100 rounded-lg hover:bg-gray-50"
                  >
                    <td className="px-3 py-3 text-left relative">
                      <div className="flex flex-row flex-wrap">
                        <div
                          className="h-20 w-20 rounded-full bg-blue-200"
                          style={{
                            backgroundImage: `url(${order.productId.image})`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                          }}
                        ></div>
                        <div className="my-auto text-gray-700 font-semibold px-2">
                          <span className="text-lg text-gray-800">
                            {order.productId.title}
                          </span>
                          <br />
                          <span className="text-sm font-bold text-gray-500">
                            {order.productId.category}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td className="">{order.orderBy.name}<br />{order.orderBy.email}</td>
                    <td className=" px-3 py-6 text-green-600 text-center">
                      {order.orderQuantity} units<br /> GHS{" "}
                      {parseInt(order.orderTotal).toFixed(2)}<br />
                      {order.commission === 0 ? '' : `Commission GHS ${order.commission.toFixed(2)}`}
                    </td>
                    <td className="text-center capitalize">
                      <span className="px-2 text-gray-600 font-semibold rounded bg-yellow-300 border-2 border-yellow-300 mb-2">
                        {order.status.replace(/_/g, " ")}
                      </span>{" "}
                      <br />
                      <span className="px-2 text-gray-600 font-semibold rounded mt-2 bg-blue-300 border-2 border-blue-300">
                        {order.paid ? "Paid" : "Not Paid"}
                      </span>
                    </td>
                    <td className="px-3 text-white py-6 text-center">
                      <div className="">
                        <input className="text-right p-1 border-2 text-black mb-2 rounded w-full" id={order._id} name={order._id} placeholder="% Commission" type="number" />
                        <button
                          onClick={(e) =>
                            createCommission(order._id)
                          }
                          className="p-2 rounded bg-green-500"
                        >
                          Deduct Commission
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="h-20"></div>
        </div>
      </div>
    </div>
  );
}

export default Orders;
