import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { signout } from "../../../Helpers/auth";
import { ToastContainer, toast } from 'react-toastify';
import { getData, postData } from "../../../Helpers/request";

function Buyers(props) {
    const [buyers, setBuyers] = useState([]);
    const [count, setCount] = useState();
    useEffect(() => {
        getCustomers();
    }, [count]);

    const getCustomers = () => {
        toast('Please Wait. Fetching Users...')
        getData("/agent/customers")
            .then((data) => {
                // console.log(data);
                if (data.error) {
                    if (data.message === "Access Denied") {
                        if (
                            window.confirm(
                                "Fetching data failed. Login again to verify your credentials"
                            )
                        ) {
                            signout();
                        }
                    } else {
                        toast.error(data.message);
                    }
                } else {
                    // console.log(data)
                    setBuyers(data.users);
                    // setCount(data.merchants.length);
                }
            })
            .catch((err) => {console.log("err", err)
            toast.error('An error occurred fetching users')
        });
    }

    return (
        <div className="h-screen overflow-auto bg-gray-50 w-full">
            <ToastContainer />
            <div className="container mx-au to w-full lg:w- 11/12 pt-10 px-5 h-full">
                <div className="container mx-auto pt-10 mt-8 px-8">
                    <h1 className="float-left font-semibold text-2xl">Users</h1>
                    <div className="clear-both"></div>
                </div>
                <div className="container mx-auto pt-10 mt-8 px-8">
                    <input
                        className="w-full rounded shadow-md hover:shadow-lg p-4 ring-1 ring-gray-100 hover:ring-gray-200"
                        placeholder="Search User..."
                    />
                </div>
                <div className="container mx-auto mt-8 px-8">
                    <h1 className="m-2 font-semibold text-lg">All Users</h1>
                    <table className="table-fixed w-full rounded-lg shadow">
                        <thead className="text-sm uppercase p-4 font-thin bg-blue-50 text-gray-400">
                            <tr className="text-center">
                                <th className="p-3 font-medium text-lef t w-1/7">Name</th>
                                <th className="p-3 font-medium w-2/7 text-r ight" colSpan="2">
                                    email
                </th>
                                {/* <th className="p-3 font-medium w-1/7 text-right">Phone</th> */}
                                <th className="pl-3 font-medium w-1/7 text-ce nter">
                                    ...
                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {buyers.map((user) => {
                                return (
                                    <tr
                                        key={user._id}
                                        className="text-center bg-white border-b-2 border-gray-100 rounded-lg hover:bg-gray-50"
                                    >
                                        <td className="px-3 py-3 text-left relative">
                                            <span className="text-lg text-gray-800">
                                                {user.name}
                                            </span>
                                        </td>
                                        <td className="" colSpan="2">
                                            {user.email}
                                        </td>
                                        <td className="px-3 py-6">{/*  colSpan="2"> */}
                                            {/* <button className="p-2 rounded bg-gray-400 hover:bg-gray-600 text-white text-sm font-bold">
                        <span className="fa fa-eye"></span>
                      </button>
                      &nbsp; */}
                                            <button className="p-2 rounded bg-green-400 hover:bg-green-600 text-white text-sm font-bold">
                                                {/* <span className="fa fa-check"></span> */}
                                                Start Chat
                      </button>
                                            {/* &nbsp;
                      <button className="p-2 rounded bg-blue-400 hover:bg-blue-600 text-white text-sm font-bold">
                        <span className="fa fa-pencil"></span>
                      </button> */}
                                            {/* &nbsp;
                      <button onClick={() => userAction('Confirm User Account Suspension', 'merchant', user._id, {status: 'suspended'})} className="p-2 rounded bg-red-400 hover:bg-red-600 text-white text-sm font-bold">
                        <span className="fa fa-trash"></span>
                      </button> */}
                                            {/* &nbsp; */}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="h-24"></div>
        </div>
    );
}

export default Buyers;
