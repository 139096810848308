import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { signout } from "../../../Helpers/auth";
import { getData, postData } from "../../../Helpers/request";
import { ToastContainer, toast } from "react-toastify";

function Users(props) {
  const [merchants, setMerchants] = useState([]);
  // const [buyers, setBuyers] = useState([]);
  const [managers, setManagers] = useState([]);
  // const [agents, setAgents] = useState([]);
  // const [count, setCount] = useState();

  useEffect(() => {
    toast.info("Fetching Users. Please Wait...");
    getMerchants();
    getManagers();
    // getAgents();
  }, []);
// }, [count]);

  const getMerchants = () => {
    getData("/admin/merchants")
      .then((data) => {
        // console.log(data);
        if (data.error) {
          if (data.message === "Access Denied") {
            if (
              window.confirm(
                "Fetching data failed. Login again to verify your credentials"
              )
            ) {
              signout();
            }
          } else {
            toast.error(data.message);
          }
        } else {
          setMerchants(data.merchants);
          // setCount(data.merchants.length);
        }
      })
      .catch((err) => console.log("err", err));
  };

  // const getAgents = () => {
  //   getData("/admin/agents")
  //     .then((data) => {
  //       // console.log(data);
  //       if (data.error) {
  //         if (data.message === "Access Denied") {
  //           if (
  //             window.confirm(
  //               "Fetching data failed. Login again to verify your credentials"
  //             )
  //           ) {
  //             signout();
  //           }
  //         } else {
  //           toast.error(data.message);
  //         }
  //       } else {
  //         setAgents(data.agents);
  //         // setCount(data.agents.length);
  //       }
  //     })
  //     .catch((err) => console.log("err", err));
  // };

  const getManagers = () => {
    getData("/admin/managers")
      .then((data) => {
        // console.log(data);
        if (data.error) {
          if (data.message === "Access Denied") {
            if (
              window.confirm(
                "Fetching data failed. Login again to verify your credentials"
              )
            ) {
              signout();
            }
          } else {
            toast.error(data.message);
          }
        } else {
          setManagers(data.managers);
          // setCount(data.managers.length);
        }
      })
      .catch((err) => console.log("err", err));
  };

  const userAction = (msg, userType, id, data) => {
    if (window.confirm(msg)) {
      toast.info("Updating User...");
      postData(`/admin/update/${userType}/${id}`, data)
        .then((e) => {
          console.log(e);
          if (userType == 'manager') {
            getManagers();
          } else {
            getMerchants();
          }
        })
        .catch((e) => console.error('error', e));
    }
  };

  return (
    <div className="h-screen overflow-auto bg-gray-50 w-full">
      <ToastContainer />
      <div className="container mx-au to w-full lg:w- 11/12 pt-10 px-5 h-full">
        <div className="container mx-auto pt-10 mt-8 px-8">
          <h1 className="float-left font-semibold text-2xl">Users</h1>
          {/* <Link
            to="/add/admin"
            className="float-right shadow rounded-2xl text-xs uppercase px-3 py-2 text-blue-500 border-2 border-blue-500 font-semibold transition mr-2 ease-in-out duration-200 hover:bg-blue-500 hover:text-white"
          >
            <i className="fa fa-plus"></i> Add Admin
          </Link> */}
          <Link
            to="/add/merchant"
            className="float-right shadow rounded-2xl text-xs uppercase px-3 py-2 text-blue-500 border-2 border-blue-500 font-semibold transition mr-2 ease-in-out duration-200 hover:bg-blue-500 hover:text-white"
          >
            <i className="fa fa-plus"></i> Add Freelancer
          </Link>
          <Link
            to="/add/manager"
            className="float-right shadow rounded-2xl text-xs uppercase px-3 py-2 text-blue-500 border-2 border-blue-500 font-semibold transition mr-2 ease-in-out duration-200 hover:bg-blue-500 hover:text-white"
          >
            <i className="fa fa-plus"></i> Add Manager
          </Link>
          <div className="clear-both"></div>
        </div>
        <div className="container mx-auto pt-10 mt-8 px-8">
          <input
            className="w-full rounded shadow-md hover:shadow-lg p-4 ring-1 ring-gray-100 hover:ring-gray-200"
            placeholder="Search User..."
          />
        </div>
        <div className="container mx-auto mt-8 px-8">
          <h1 className="m-2 font-semibold text-lg">Freelancers</h1>
          <table className="table min-w-96 rounded-lg shadow">
            <thead className="text-sm uppercase p-4 font-thin bg-blue-50 text-gray-400">
              <tr className="text-center">
                <th className="p-3 font-medium text-le ft w-2/9">Business</th>
                <th className="p-3 font-medium text-lef t w-1/9">Name</th>
                <th className="p-3 font-medium w-2/9 text-r ight" colSpan="2">
                  email
                </th>
                <th className="p-3 font-medium w-1/9 text-r ight">
                  phone
                </th>
                <th className="p-3 font-medium w-1/9 text-r ight">Document</th>
                <th className="p-3 font-medium w-1/9 text-r ight">Status</th>
                <th className="pl-3 font-medium w-1/9 text-ce nter">...</th>
              </tr>
            </thead>
            <tbody>
              {merchants.map((user) => {
                return (
                  <tr
                    key={user._id}
                    className="text-center bg-white border-b-2 border-gray-100 rounded-lg hover:bg-gray-50"
                  >
                    <td className="px-3 py-3 text-left relative">
                      <span className="text-lg text-gray-800">
                        {user.business}
                      </span>
                    </td>
                    <td className="">{user.name}</td>
                    <td className="" colSpan="2">
                      {user.email}
                    </td>
                    <td className="capitalize">{user.phone}</td>
                    <td className="capitalize text-blue-500 underlined p-2 text-center"><a href={user.certificate[0]} target="_blank">View Here</a></td>
                    <td className="capitalize">{user.status}</td>
                    <td className="px-3 py-6">
                      {/*  colSpan="2"> */}
                      {/* <button className="p-2 rounded bg-gray-400 hover:bg-gray-600 text-white text-sm font-bold">
                        <span className="fa fa-eye"></span>
                      </button>
                      &nbsp; */}
                      <button
                        onClick={() =>
                          userAction(
                            "Confirm User's Approval",
                            "merchant",
                            user._id,
                            { status: "active" }
                          )
                        }
                        className="p-2 rounded bg-green-400 hover:bg-green-600 text-white text-sm font-bold"
                      >
                        <span className="fa fa-check"></span>
                      </button>
                      {/* &nbsp;
                      <button className="p-2 rounded bg-blue-400 hover:bg-blue-600 text-white text-sm font-bold">
                        <span className="fa fa-pencil"></span>
                      </button> */}
                      &nbsp;
                      <button
                        onClick={() =>
                          userAction(
                            "Confirm User Account Suspension",
                            "merchant",
                            user._id,
                            { status: "suspended" }
                          )
                        }
                        className="p-2 rounded bg-red-400 hover:bg-red-600 text-white text-sm font-bold"
                      >
                        <span className="fa fa-trash"></span>
                      </button>
                      &nbsp;
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="container mx-auto mt-8 px-8">
          <h1 className="m-2 font-semibold text-lg">Sales Managers</h1>
          <table className="table-fixed w-full rounded-lg shadow">
            <thead className="text-sm uppercase p-4 font-thin bg-blue-50 text-gray-400">
              <tr className="text-center">
                <th className="p-3 font-medium text-lef t w-2/6" colSpan="2">Name</th>
                <th className="p-3 font-medium text-le ft w-1/6">Phone</th>
                <th className="p-3 font-medium w-2/6 text-r ight" colSpan="2">email</th>
                {/* <th className="p-3 font-medium w-1/6 text-right">Phone</th> */}
                <th className="p-3 font-medium w-1/6 text-r ight">Status</th>
                <th className="pl-3 font-medium w-1/6 text-ce nter">
                  ...
                </th>
              </tr>
            </thead>
            <tbody>
              {managers.map((user) => {
                return (
                  <tr
                    key={user._id}
                    className="text-center bg-white border-b-2 border-gray-100 rounded-lg hover:bg-gray-50"
                  >
                    <td className="px-3 py-3 text-left relative" colSpan="2">
                      <span className="text-lg text-gray-800">{user.name}</span>
                    </td>
                    <td className="">{user.phone}</td>
                    <td className="" colSpan="2">
                      {user.email}
                    </td>
                    <td className="capitalize">{user.status}</td>
                    <td className="px-3 py-6 text-right" colSpan="2">
                      {/* <button className="p-2 rounded bg-gray-400 text-white text-sm font-bold">
                        <span className="fa fa-eye"></span>
                      </button>
                      &nbsp; */}
                      <button
                        onClick={() =>
                          userAction(
                            "Confirm User's Approval",
                            "manager",
                            user._id,
                            { status: "active" }
                          )
                        }
                        className="p-2 rounded bg-green-400 text-white text-sm font-bold">
                        <span className="fa fa-check"></span>
                      </button>
                      &nbsp;
                      {/* <button className="p-2 rounded bg-blue-400 text-white text-sm font-bold">
                        <span className="fa fa-pencil"></span>
                      </button>
                      &nbsp; */}
                      <button
                        onClick={() =>
                          userAction(
                            "Confirm User's Suspension",
                            "manager",
                            user._id,
                            { status: "suspended" }
                          )
                        }
                        className="p-2 rounded bg-red-400 text-white text-sm font-bold">
                        <span className="fa fa-trash"></span>
                      </button>
                      &nbsp;
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="h-24"></div>
      </div>
    </div>
  );
}

export default Users;
