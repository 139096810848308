import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { signout } from "../../../Helpers/auth";
import { getData, postData } from "../../../Helpers/request";
import { toast } from "react-toastify";

function Report(props) {
  const [orders, setOrders] = useState([]);
  const [count, setCount] = useState(0);
  const [pending, setPending] = useState(0);
  const [cancelled, setCancelled] = useState(0);
  const [processing, setProcessing] = useState(0);
  const [completed, setCompleted] = useState(0);
  const [pendingPrice, setPendingPrice] = useState(0);
  const [cancelledPrice, setCancelledPrice] = useState(0);
  const [processingPrice, setProcessingPrice] = useState(0);
  const [completedPrice, setCompletedPrice] = useState(0);
  const [merchants, setMerchants] = useState([]);
  const [commission, setCommission] = useState(0);
  const [orderGroups, setOrderGroups] = useState([]);

  useEffect(() => {
    const _gd = () => {
      getData("/admin/merchants")
        .then((d) => {
          console.log(d);
          if (d.merchants) {
            setMerchants(d.merchants);
          }
        })
        .catch((d) => console.error("err", d));
    };
    _gd();
    getOrders().then((e) => {
      let _mobj = {};
      for (var i in merchants) {
        _mobj[merchants[i]._id] = [];
      }
      for (var i in orders) {
        if (orders[i].merchantId) {
          _mobj[orders[i].merchantId].push(orders[i]);
        }
      }
      setOrderGroups(_mobj);
      //   console.log("grouped orders", _mobj);
    });
  }, [count]);

  const getOrders = () => {
    return getData("/manager/orders")
      .then((data) => {
        console.log(data);
        if (data.error) {
          if (data.message === "Access Denied") {
            if (
              window.confirm(
                "Fetching data failed. Login again to verify your credentials"
              )
            ) {
              signout();
            }
          } else {
            toast(data.message);
          }
        } else {
          setOrders(data.orders);
          // console.log(data.orders);
          let _p = 0;
          let _pp = 0;
          let _c = 0;
          let _cc = 0;
          let _pr = 0;
          let _prpr = 0;
          let _ca = 0;
          let _caca = 0;
          let __c = 0;
          data.orders.flatMap((o) => {
            __c += o.commission;
            if (o.orderStatus === "pending") {
              _pp += o.orderTotal;
              _p += 1;
            } else if (o.orderStatus === "completed") {
              _cc += o.orderTotal;
              _c += 1;
            } else if (o.orderStatus === "processing") {
              _prpr += o.orderTotal;
              _pr += 1;
            } else if (o.orderStatus === "cancelled") {
              _caca += o.orderTotal;
              _ca += 1;
            }

            return [];
          });

          setCommission(__c);
          setPending(_p);
          setPendingPrice(_pp.toFixed(2));
          setCompleted(_c);
          setCompletedPrice(_cc.toFixed(2));
          setCancelled(_ca);
          setCancelledPrice(_caca.toFixed(2));
          setProcessing(_pr);
          setProcessingPrice(_prpr.toFixed(2));

          setCount(data.orders.length);
        }
      })
      .catch((err) => console.log("err", err));
  };
  var f = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'GHS',
  });

  return (
    <div className="h-screen bg-gray-50 w-full overflow-auto">
      <div className="container mx-au to w-11/12 pt-10 px-5 h-full">
        <div className="container mx-auto pt-10 mt-8 px-8">
          <h1 className="font-semibold text-3xl">General Report</h1>
          <button onClick={() => setCount(count + 1)}>Refresh</button>
          {/* <div className="clear-both"></div> */}
        </div>
        <div className="container mx-auto pt-10 mt-8 px-8">
          <div className="container">
            <h1 className="font-bold text-2xl mb-4">Order Summary</h1>
            <div className="flex flex-wrap border-2 bg-gray-100 font-bold text-xl">
              <div className="flex-grow border-r-2 p-4">
                Total Commission Generated
              </div>
              <div className="p-4 font-bold">{f.format(commission)}</div>
            </div>
            <div className="h-16"></div>
            <div className="flex flex-wrap border-2 bg-gray-200 text-sm font-bold">
              <div className="flex-grow border-r-2 p-4">Title</div>
              <div className="p-4 border-r-2">Quantity</div>
              <div className="p-4">Price</div>
            </div>
            <div className="flex flex-wrap border-2 text-xl font-semibold bg-bl ue-200">
              <div className="flex-grow border-r-2 p-4">
                Total Orders Placed
              </div>
              <div className="p-4 border-r-2">{orders.length}</div>
              <div className="p-4">
                {f.format(parseFloat(pendingPrice) +
                  parseFloat(cancelledPrice) +
                  parseFloat(completedPrice) +
                  parseFloat(processingPrice))}
              </div>
            </div>
            <div className="flex flex-wrap border-2 bg-gray-200">
              <div className="flex-grow border-r-2 p-4 font-bold">
                Pending Orders
              </div>
              <div className="p-4 border-r-2">{pending}</div>
              <div className="p-4">{f.format(pendingPrice)}</div>
            </div>
            <div className="flex flex-wrap border-2 bg-red-200">
              <div className="flex-grow border-r-2 p-4 font-bold">
                Cancelled Orders
              </div>
              <div className="p-4 border-r-2">{cancelled}</div>
              <div className="p-4">{f.format(cancelledPrice)}</div>
            </div>
            <div className="flex flex-wrap border-2 bg-blue-200">
              <div className="flex-grow border-r-2 p-4 font-bold">
                Processing Orders
              </div>
              <div className="p-4 border-r-2">{processing}</div>
              <div className="p-4">{f.format(processingPrice)}</div>
            </div>
            <div className="flex flex-wrap border-2 bg-green-200">
              <div className="flex-grow border-r-2 p-4 font-bold">
                Completed Orders
              </div>
              <div className="p-4 border-r-2">{completed}</div>
              <div className="p-4">{f.format(completedPrice)}</div>
            </div>

            <div className="h-16"></div>
            <div className="flex flex-wrap border-2 bg-gray-100 font-bold text-xl">
              <div className="flex-grow border-r-2 p-4">Merchants</div>
              <div className="p-4 font-bold">Total {merchants.length}</div>
            </div>
            {merchants.map((m, _i) => {
              let _pending, _completed, _cancelled, _processing;
              _pending = { count: 0, price: 0 };
                _completed = { count: 0, price: 0 };
                _cancelled = { count: 0, price: 0 };
                _processing = { count: 0, price: 0 };
              if (orderGroups[m._id]) {
                orderGroups[m._id].map((_mi) => {
                  if (_mi.status === "pending") {
                    _pending.count += 1;
                    _pending.price += _mi.orderTotal;
                  } else if (_mi.status === "processing") {
                    _processing.count += 1;
                    _processing.price += _mi.orderTotal;
                  } else if (_mi.status === "cancelled") {
                    _cancelled.count += 1;
                    _cancelled.price += _mi.orderTotal;
                  } else if (_mi.status === "completed") {
                    _completed.count += 1;
                    _completed.price += _mi.orderTotal;
                  }
                });
              }
              return (
                <div
                  key={m._id}
                  className={`flex flex-wrap border-0 ${parseInt(_i/2) === _i/2 ? 'bg-white' : 'bg-gray-100'} font-bold text-xl`}
                >
                  <div className="flex-grow border-r-2 border-b-2 p-4">
                    {m.business}&nbsp; <br /> Total Orders{" - "}
                    {orderGroups[m._id] && orderGroups[m._id].length}
                  </div>
                  <div className="p-4 border-r-2 bg-green-50 border-b-2 font-bold">
                    Completed ({_completed.count})
                    <br /> <span className="text-2xl text-green-900">{f.format(_processing.price)}</span>
                  </div>
                  <div className="p-4 border-r-2 bg-blue-50 border-b-2 font-bold">
                    Processing ({_processing.count})
                    <br /> <span className="text-2xl text-blue-900">{f.format(_processing.price)}</span>
                  </div>
                  <div className="p-4 border-r-2 bg-yellow-50 border-b-2 font-bold">
                    Pending ({_pending.count})
                    <br /> <span className="text-2xl text-yellow-900">{f.format(_pending.price)}</span>
                  </div>
                  <div className="p-4 border-r-2 bg-red-50 border-b-2 font-bold">
                    Cancelled ({_cancelled.count})
                    <br /> <span className="text-2xl text-red-900">{f.format(_cancelled.price)}</span>
                  </div>
                </div>
              );
            })}
            <div className="h-20"></div>
          </div>
        </div>
      </div>
      <div className="h-20"></div>
    </div>
  );
}

export default Report;
