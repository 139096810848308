import React from "react";
import { Link } from "react-router-dom";

function Parts(props) {
  return (
    <div className="h-screen bg-gray-50 w-full">
      <div className="container mx-au to w-11/12 pt-10 px-5 h-full">
        <div className="container mx-auto pt-10 mt-8 px-8">
          <h1 className="float-left font-semibold text-2xl">Parts</h1>
          <Link to="/part/add" className="float-right shadow bg-gray-800 rounded-2xl text-xs uppercase px-3 py-2 text-gray-50 font-semibold hover:bg-gray-700 hover:shadow-lg">
            <i className="fa fa-plus"></i> Add Part
          </Link>
          <div className="clear-both"></div>
        </div>
        <div className="container mx-auto pt-10 mt-8 px-8">
          <input
            className="w-full rounded shadow-md hover:shadow-lg p-4 ring-1 ring-gray-100 hover:ring-gray-200"
            placeholder="Search"
          />
        </div>
        <div className="container mx-auto mt-4 px-8">
          <table className="table-fixed w-full rounded-lg shadow">
            <thead className="text-sm uppercase p-4 font-thin bg-blue-50 text-gray-400">
              <tr className="">
                <th className="p-3 font-medium text-left w-3/5">Part</th>
                <th className="p-3 font-medium w-1/5 text-right">Price</th>
                <th className="p-3 font-medium w-1/5 text-right">Options</th>
              </tr>
            </thead>
            <tbody>
              <tr className="text-center bg-white rounded-lg hover:bg-gray-50">
                <td className=" px-3 py-3 text-left relative">
                  {/* <div className="h-16 w-16 rounded-full bg-blue-100 inline-block"></div>  */}
                  <div className="py-auto my-auto text-gray-700 font-semibold align-middle inline-block"><span> Hoodie</span></div>
                </td>
                <td className=" px-3 py-6 text-green-600 text-right">$0.00</td>
                <td className="px-3 py-6 text-right">
                  <i className="fa fa-ellipsis-h"></i>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Parts;
