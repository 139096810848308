import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { authenticate, isAuth } from "../../Helpers/auth";
import { postData } from "../../Helpers/request";
import { toast } from "react-toastify";

function Manager(props) {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')
  const [btnTxt, setBtnTxt] = useState('Register')
  const h = useHistory()
  
  const handleSubmit = e => {
    e.preventDefault()
    setBtnTxt('Registering you...')
    if(true) {
      postData('/manager/register', {name, phone, email, password})
      .then(data => {
        console.log(data)
        toast(data.message)
        if(data.error) {

        }

        setBtnTxt('Register')
      })
      .catch(e => console.log('err', e))
    }
  }
  return (
    <div className="w-full h-screen bg-gray-200 flex justify-center content-center">
      {/* {logIn ? h.push('/dashboard') : null } */}
      <div className="m-auto bg-white shadow-lg border-2 rounded border-gray-100">
        <div className="w-75 font-bold text-gray-600 mb-3 text-xl">
          <h1 className="cursor-pointer">
            <Link
              to="/manager/register"
              className="float-left p-6 text-gray-400"
            >
              Manager Signup&nbsp;
            </Link>
            <Link className="float-right p-6 bg-gray-100 text-green-500" to="/manager/login">
              &nbsp;Manager Login
            </Link>
          </h1>
        </div>
        <div className="border-t-2 border-gray-50 clear-both"></div>
        <form className="my-4 p-6" onSubmit={handleSubmit}>
          <div className="mt-3">
            <input
              className="w-full shadow rounded-md border-2 border-gray-100 hover:border-gray-200 p-3 font-semibold"
              placeholder="Name"
              name="name"
              type="name"
              value={name}
              onChange={e => setName(e.target.value)}
              autoComplete="name"
            />
          </div>
          <div className="mt-3">
            <input
              className="w-full shadow rounded-md border-2 border-gray-100 hover:border-gray-200 p-3 font-semibold"
              placeholder="Email"
              name="email"
              type="email"
              value={email}
              onChange={e => setEmail(e.target.value)}
              autoComplete="email"
            />
          </div>
          <div className="mt-3">
            <input
              className="w-full shadow rounded-md border-2 border-gray-100 hover:border-gray-200 p-3 font-semibold"
              placeholder="Phone"
              name="phone"
              type="phone"
              value={phone}
              onChange={e => setPhone(e.target.value)}
              autoComplete="phone"
            />
          </div>
          <div className="mt-3">
            <input
              className="w-full shadow rounded-md border-2 border-gray-100 hover:border-gray-200 p-3 font-semibold"
              placeholder="Password"
              name="password"
              type="password"
              value={password}
              onChange={e => setPassword(e.target.value)}
              autoComplete="current-password"
            />
          </div>
          <div className="mt-3">
            <button className="w-full shadow rounded-md border-2 border-gray-100 hover:border-gray-200 bg-green-500 hover:bg-green-400 text-white p-3 font-semibold" type="submit">
              {btnTxt}
            </button>
            <div className="text-blue-500 text-right">
              <label className="cursor-pointer">
                <Link to="/">Go back Home</Link>
              </label>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Manager;
