import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { authenticate, isAuth } from "../../Helpers/auth";
import { postData } from "../../Helpers/request";
import { ToastContainer, toast } from "react-toastify";

function AgentLogin(props) {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [logIn, setLogIn] = useState(isAuth() ? true : false)
  const h = useHistory()

  const handleSubmit = e => {
    e.preventDefault()
    if (true) {
      postData('/agent/login', { email, password })
        .then(data => {
          console.log(data)
          toast(data.message)
          if (data.error) {

          } else {
            authenticate(data, () => {
              // setLogIn(isAuth() ? true : false)
              h.push('/home')
              window.location.reload()
            })
          }
        })
    }
  }
  return (
    <div className="w-full h-screen bg-gray-200 flex justify-center content-center">
      <ToastContainer />
      {logIn ? h.push('/home') : null}
      <div className="m-auto bg-white shadow-lg border-2 rounded border-gray-100">
        <div className="w-75 font-bold text-gray-600 mb-3 text-xl">
          <h1 className="cursor-pointer">
            <Link
              to="/agent/register"
              className="float-left p-6 bg-gray-100 text-gray-400"
            >
              Agent Signup&nbsp;
            </Link>
            <Link className="float-right p-6 text-green-500" to="/agent/login">
              &nbsp;Agent Login
            </Link>
          </h1>
        </div>
        <div className="border-t-2 border-gray-50 clear-both"></div>
        <form className="my-4 p-6" onSubmit={handleSubmit}>
          <div className="mt-3">
            <input
              className="w-full shadow rounded-md border-2 border-gray-100 hover:border-gray-200 p-3 font-semibold"
              placeholder="Email"
              name="email"
              type="email"
              value={email}
              onChange={e => setEmail(e.target.value)}
              autoComplete="email"
            />
          </div>
          <div className="mt-3">
            <input
              className="w-full shadow rounded-md border-2 border-gray-100 hover:border-gray-200 p-3 font-semibold"
              placeholder="Password"
              name="password"
              type="password"
              value={password}
              onChange={e => setPassword(e.target.value)}
              autoComplete="current-password"
            />
          </div>
          <div className=" text-right">
            <small className="text-sm text-blue-400">Forgot Password?</small>
          </div>
          <div className="hover:text-blue-500 text-blue-800">
            <label className="cursor-pointer">
              <input
                className="border-2 border-gray-300 shadow-sm"
                name="remember"
                type="checkbox"
              />{" "}
              Remember Me
            </label>
          </div>
          <div className="mt-3">
            <button className="w-full shadow rounded-md border-2 border-gray-100 hover:border-gray-200 bg-green-500 hover:bg-green-400 text-white p-3 font-semibold" type="submit">
              Login
            </button>
            <div className="text-blue-500 text-right">
              <label className="cursor-pointer">
                <Link to="/">Go back Home</Link>
              </label>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AgentLogin;
