import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { signout } from "../../../Helpers/auth";
import { getData, postData } from "../../../Helpers/request";

function Orders(props) {
  const [orders, setOrders] = useState([]);
  const [count, setCount] = useState(0);

  useEffect(() => {
    getOrders();
  }, [count]);

  const getOrders = () => {
    getData("/manager/orders")
      .then((data) => {
        // console.log(data);
        if (data.error) {
          if (data.message === "Access Denied") {
            if (
              window.confirm(
                "Fetching data failed. Login again to verify your credentials"
              )
            ) {
              signout();
            }
          } else {
            console.log(data.message);
          }
        } else {
          setOrders(data.orders);
          console.log(data.orders);
          setCount(data.orders.length);
        }
      })
      .catch((err) => console.log("err", err));
  };

  const updateOrder = (id, data) => {
    postData(`/manager/order/${id}`, data)
      .then((e) => console.log(e))
      .catch((e) => console.error("err", e));
  };
  
  const createCommission = (id) => {
    let _cVal = parseFloat(document.getElementById(id).value)
    if (_cVal > 0 && _cVal < 100) {
      postData(`/agent/order/commission/${id}`, { percent: _cVal })
        .then((e) => {
          console.log(e)
          toast.info(e.order.message)
          setCount(count++)
        })
        .catch((e) => console.error("err", e));
    } else {
      toast.error('Commission Percentage has to be a numberic value greater than 0 and less than 100')
    }
  };

  const sendMail = (id) => {
    toast.info('Posting Receipt to user via e-Mail')
    postData(`/merchant/mail/${id}`, {})
      .then((e) => console.log(e))
      .catch((e) => console.error("err", e));
  };

  return (
    <div className="h-screen bg-gray-50 w-full overflow-auto">
      <ToastContainer />
      <div className="container mx-au to w-full pt-10 px-5 h-full">
        <div className="container mx-auto pt-10 mt-8 px-8">
          <h1 className="font-semibold text-2xl">Orders</h1>
          <button onClick={() => setCount(count + 1)}>Refresh</button>
          {/* <div className="clear-both"></div> */}
        </div>
        <div className="container mx-auto pt-10 mt-8 px-8">
          <input
            className="w-full rounded shadow-md hover:shadow-lg p-4 ring-1 ring-gray-100 hover:ring-gray-200"
            placeholder="Search Order..."
          />
        </div>
        <div className="c ontainer mx-auto w-200 overflow-auto md:my-4 md:px-8">
          <table className="table min-w-96 rounded-lg shadow">
            <thead className="text-sm uppercase p-4 font-thin bg-blue-50 text-gray-400">
              <tr className="">
                <th className="p-3 font-medium text-left w-1/7">Ordered Product</th>
                <th className="p-3 font-medium w-2/7">Customer</th>
                <th className="p-3 font-medium w-1/7 text-right">Qty & Cst</th>
                <th className="p-3 font-medium w-1/7 text-right">Status</th>
                <th className="p-3 font-medium w-2/7 text-right">Deduct Commission</th>
                <th className="p-3 font-medium w-1/7 text-right">Update Status</th>
              </tr>
            </thead>
            <tbody>
              {orders.map((order) => {
                return (
                  <tr 
                    key={order._id}
                    className="text-center bg-white border-b-2 border-gray-100 rounded-lg hover:bg-gray-50"
                  >
                    <td className="px-3 py-3 text-left relative">
                      <div className="flex flex-row flex-wrap">
                        <div
                          className="h-20 w-20 rounded-full bg-blue-200"
                          style={{
                            backgroundImage: `url(${order.productId.image})`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                          }}
                        ></div>
                        <div className="my-auto text-gray-700 font-semibold px-2">
                          <span className="text-lg text-gray-800">
                            {order.productId.title}
                          </span>
                          <br />
                          <span className="text-sm font-bold text-gray-500">
                            {order.productId.category}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td className="">{order.orderBy.name}<br />{order.orderBy.email}</td>
                    <td className=" px-3 py-6 text-green-600 text-right">
                      {order.orderQuantity} units<br /> GHS{" "}
                      {parseInt(order.orderTotal).toFixed(2)}
                    </td>
                    <td className="text-right capitalize">
                      <span className="px-2 text-gray-600 font-semibold rounded bg-yellow-300 border-2 border-yellow-300 mb-2">
                        {order.status.replace(/_/g, " ")}
                      </span>{" "}
                      <br />
                      <span className="px-2 text-gray-600 font-semibold rounded mt-2 bg-blue-300 border-2 border-blue-300">
                        {order.paid ? "Paid" : "Not Paid"}
                      </span>
                    </td>
                    <td className="px-3 text-white py-6 text-right">
                      <div className="">
                        <input className="text-right p-1 border-2 text-black mb-2 rounded w-auto" id={order._id} name={order._id} placeholder="% Commission" type="number" />
                        <button
                          onClick={(e) =>
                            createCommission(order._id)
                          }
                          className="p-2 rounded bg-green-500"
                        >
                          Deduct
                        </button>
                      </div>
                    </td>
                    <td className="px-3 text-white py-6 text-right">
                      <div className="">
                        <button
                          onClick={(e) =>
                            updateOrder(order._id, { paid: true })
                          }
                          className="whitespace-nowrap p-1 mb-1 rounded bg-green-500"
                        >
                          Confirm Payment
                        </button> <br />
                        <button
                          onClick={(e) =>
                            updateOrder(order._id, { paid: true })
                          }
                          className="whitespace-nowrap mb-1 p-1 rounded bg-blue-500"
                        >
                          Quality Check
                        </button> <br />
                    <button
                      onClick={(e) =>
                        sendMail(order._id)
                      } className="ml-1 mb-1 p-1 rounded bg-yellow-500">Post Receipt</button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      <div className="h-20"></div>
      </div>
    </div>
  );
}

export default Orders;
